export enum FeedbackSubmitStatus {
    PENDING='PENDING', 
    SUBMITTED='SUBMITTED'
}

export enum FeedbackReadStatus {
    UNREAD='UNREAD',
    READ='READ'
}


export enum Page{
    Project = "Project",
    CareerMangerTeam = "Career Manager Team"
}