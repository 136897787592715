<div class="sidenav_container">
  <div fxLayout="column" fxLayoutGap="10px" style="height: 100%;">
    <div class="user_menu">
      <mat-nav-list>
        <a mat-list-item>
          <img class="logo-img" src="assets/pulse-logo.png" alt="">
          <span *ngIf="this.userDetail.getUserDetails() && isExpanded" 
            class="pulse-name">PULSE</span>
        </a>
      </mat-nav-list>
    </div>

    <div class="user_menu">
      <mat-nav-list>
        <a mat-list-item>
          <img class="jim" src="assets/female.png" alt="">
          <mat-icon class="toggle" *ngIf="!this.isExpanded" (click)="togglebutton()">keyboard_double_arrow_right
          </mat-icon>
          <mat-icon class="toggleRight" *ngIf="this.isExpanded" (click)="togglebutton()">keyboard_double_arrow_left
          </mat-icon>
          <span *ngIf="this.userDetail.getUserDetails() && isExpanded" class="pl-2">{{
            this.userDetail.getUserDetails().firstName }} {{ this.userDetail.getUserDetails().lastName }}</span>
        </a>
      </mat-nav-list>
    </div>
    
    <mat-divider></mat-divider>
    
    <div>
      <mat-nav-list id="navitems">
        <a mat-list-item *ngFor="let page of this.loginService.pages" [matTooltip]="page.tooltip"
          matTooltipPosition="right" [routerLink]="page?.link"
          [ngStyle]="{'display': page.display == false ? 'none' : 'block' } " routerLinkActive="active-list-item">
          <mat-icon class="icon" *ngIf="page?.name != 'My Tasks'" style="padding-right:5px ">{{page?.icon}}</mat-icon>
          <mat-icon class="icon" *ngIf="page?.name == 'My Tasks'"
            [matBadge]="this.badgeService.feedbackBadgeCount>0?this.badgeService.feedbackBadgeCount:''"
            matBadgeColor="warn" style="padding-right:5px;">{{page?.icon}}</mat-icon>
          <span *ngIf="isExpanded" class="pl-3">{{ page?.name }} </span>
        </a>
      </mat-nav-list>
    </div>

    <div>
      <mat-nav-list id="navitems">
        <mat-divider></mat-divider>
        <a mat-list-item class="logout_btn">
          <button matTooltip="Logout" *ngIf="this.userDetail.getUserDetails()" mat-icon-button
            (click)="this.authGuardService.signOut()">
            <mat-icon class="logout_icn">logout</mat-icon><span *ngIf="isExpanded"
              class="logout-text">Logout</span>
          </button> </a>
      </mat-nav-list>
    </div>



  </div>

</div>