import { User } from '../models/user';
import { ReviewStatus } from '../services/employeeReview/employeeReview-enum';

export class EmployeeReviewDTO {
    id: string;
    careerManagerId: string
    dueDate: Date
    employeeId: string
    oneOnOneMeetingId: string
    reviewCycle: string
    reviewStatus: ReviewStatus
    reviewType: string
    constructor() {
        this.careerManagerId = "";
        this.dueDate = new Date();
        this.employeeId = "";
        this.oneOnOneMeetingId = "";
        this.reviewCycle = "";
        this.reviewStatus = ReviewStatus.PENDING
        this.reviewType = "";
    }
}