import { Injectable } from '@angular/core';
import { Client } from '../../models/client';
import { PageEvent } from '@angular/material/paginator';
import { RestAPIService } from '../restAPI/rest-api.service';
import { ClientURLService } from '../restAPI/clientURLs/Client-url.service';
@Injectable({
  providedIn: 'root'
})
export class ClientService {
  clients = [];
  images = [];
  totalCount;
  page: PageEvent
  pageCount = 1
  pageSize = 6
  constructor(private restAPiService: RestAPIService, private clientURLService: ClientURLService) { }
  getClients() {
    this.restAPiService.get(this.clientURLService.getBaseUrl()).subscribe(res => {
      this.setClients(res.body);
    })
  }

  getClientByIdSubscribe(id){
    return this.restAPiService.get(this.clientURLService.getByFieldUrl(id))
  }
  getClientsSubscribe() {
    return this.restAPiService.get(this.clientURLService.getBaseUrl())
  }
  getClientsPagination() {
    this.restAPiService.get(
      this.clientURLService.getPaginationUrl(this.pageCount, this.pageSize)
    )
    .subscribe(res => {
      this.setClients(res.body);
      this.totalCount = Number(res.headers.getAll('X-Total-Count'));
    })
  }
  searchClientByText(event) {
    this.restAPiService.get(
      this.clientURLService.getSearchUrl(event)
    ).subscribe(res => {
      this.setClients(res.body);
    });
  }
  setClients(clients) {
    this.clients = [];
    for (let client of clients) {
      this.clients.push(new Client(client));
    }
  }
}
