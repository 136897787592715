<div style="position: relative;">
    <button mat-icon-button (click)="closeDialog()"
        style="color:white; position: absolute;z-index: 1;  top: 15px; right: 10px;">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="container-fluid ">
    <div class="row" style="height: 60px; color: white;background-color: #3D4A74;">
        <div class="headingTitle">
            <h2>Submit Meeting</h2>
        </div>
    </div>
    <form [formGroup]="oneOneOneMeetingForm" (ngSubmit)="onSubmit()">
        <div class="row shadow-sm" style="height: 70px;">

            <div class="col-1 imgCol" style="text-align: center;"><span class="mx-auto"><img class="img-style"
                        [src]="this.initialAvatarService.getAvatar()"></span>
            </div>
            <div class="col-3 ml-0 pl-0 userName">

                <p class="h3">{{this.userService.processName(this.data.careerManager)}}</p>
            </div>
            <div class="col-6">
            </div>
            <div class="col-2 submitBtnCol">
                <button *ngIf="!this.data.disableIfCompleted" mat-raised-button
                class="bg-success btn-success px-auto" style="color: white;">Submit</button>
            </div>
        </div>

        <div class="row formContentCol">

            <div class="col">
                <div class="row">
                    <div class="col-12">
                        <p class='h5'>Meeting Date</p>
                    </div>
                    <div class="col-12">

                        <mat-form-field appearance="standard">
                            <mat-label>Select Meeting Date</mat-label>
                            <input matInput [matDatepicker]="picker" formControlName="meetingDate">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class='h5'>Meeting Summary</p>
                    </div>
                    <div class="col-12">

                        <mat-form-field style="width: 600px;" appearance="fill">
                            <mat-label>Write your meeting summary</mat-label>
                            <textarea matInput formControlName="meetingSummary" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="7"
                                cdkAutosizeMaxRows="20"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="col-12 ">
                        <p class='h5 ml-5'>Meeting Participants</p>
                    </div>
                </div>
                <div class="row">
                    <app-participant-card class="ml-5" [user]=this.data.employeeReview.employee>
                    </app-participant-card>
                </div>

                <div class="row mt-4">
                    <app-participant-card class="ml-5" [user]=this.data.careerManager>
                    </app-participant-card>

                </div>

            </div>

        </div>
    </form>

</div>