import { EvaluationConfiguration } from "../models/evaluationConfiguration";

export class EvaluationConfigurationDTO{
    public id;
    public careerManagerWeightage:number;
    public clientWeightage:number;
    public managerWeightage:number;
    public peerWeightage:number;
    public supervisorWeightage:number;
    
    public userWeightageId:string;
    public userWeightageLogin:string;

    constructor(){
        this.careerManagerWeightage = 0
        this.clientWeightage = 0
        this.managerWeightage = 0
        this.peerWeightage = 0
        this.supervisorWeightage = 0

        this.userWeightageId = ''
        this.userWeightageLogin = ''
       
    }

    setDto(evalConf:EvaluationConfiguration){
        this.id = evalConf.id;
        this.careerManagerWeightage = evalConf.careerManagerWeightage
        this.clientWeightage = evalConf.clientWeightage
        this.managerWeightage = evalConf.managerWeightage
        this.peerWeightage = evalConf.peerWeightage
        this.supervisorWeightage = evalConf.supervisorWeightage

        this.userWeightageId = evalConf.userWeightageId
        this.userWeightageLogin = evalConf.userWeightageLogin
    }


    
}