import { Injectable } from '@angular/core';
import { RestAPIService } from '../rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectURLService {

 
  private readonly projectBaseApi = '/api/projects';
  private readonly search = '/search';
  private readonly user = '/user'
  constructor(private restApi:RestAPIService) { 
    
  }

  getBaseUrl(){
    return this.projectBaseApi;
  }

  getPaginationUrl(pageCount,pageSize){
    pageCount-=1;
    return this.projectBaseApi+'?eagerload=false&'+'page='+pageCount+'&size='+pageSize;
  }

  getSearchUrl(searchText:string){
    return this.projectBaseApi+this.search+this.getByField(searchText)
  }

  getUserByIdUrl(id){
    return this.projectBaseApi+this.user+this.getByField(id);
  }

  //This will be used for get by id and delete by id
  getByFieldUrl(field){
    return this.projectBaseApi+ this.getByField(field)
  }

  private getByField(field){
    return '/'+field;
  }

  
}
