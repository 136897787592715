import { ChangeDetectorRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { CircleLoaderService } from 'src/app/services/cirle-loader.service';

@Component({
  selector: 'app-circle-loader',
  templateUrl: './circle-loader.component.html',
  styleUrls: ['./circle-loader.component.scss']
})
export class CircleLoaderComponent implements OnInit {

  showSpinner = true;

  constructor(private cicrleLoadService: CircleLoaderService, private cdRef: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.init();
  }

  init() {

    this.cicrleLoadService.getCircleLoaderObserver().subscribe((status) => {
      // this.showSpinner = (status === 'start');
      this.cdRef.detectChanges();
    });
  }
}
