import { FormSchemaDTO } from "./FormSchemaDTO";
import { UserDTO } from "./UserDTO";

export class ProjectDTO {
    public id: string;
    public projectName: string;
    public projectDescription: string;
    public dateCreated: Date;
    public managerId: string;
    public users: UserDTO[];
    public supervisors: UserDTO[];
    public formSchemas: FormSchemaDTO[];
    public clientId: string;
    public projectCode:string;
    public department:string;
    constructor() {
        this.id = null;
        this.projectName = "";
        this.projectDescription = "";
        this.dateCreated = new Date();
        this.managerId = "";
        this.users = [];
        this.supervisors = [];
        this.formSchemas = [];
        this.clientId = "";
        this.projectCode = "";
        this.department = "";
    }
}