<!-- employee list view starts -->

<div class="row">
    <div class="col-md-12" *ngIf="hasRoleBased && display === 'list' ">
        <button class="add_Emp_btn" (click)="addEmployeeDialog()"> <span class="add_em_name">+ Add Employee</span>
        </button>
    </div>

</div>
<div class="row">
    <div class="col-md-12" *ngFor="let empDetail of userDto; let i = index">
        <mat-card class="list-card mt-1" *ngIf="display === 'list' " [ngClass]="i%2==0? 'evenitem' : 'odditem'">
            <div class="col-md-2">
                <div class="row">
                    <div class="col-md-5">
                        <img class="logo-img" src="assets/female.png" alt="">
                    </div>
                    <div class="col-md-6">
                        <label class="firstAndLastname ml-0">{{empDetail.firstName + ' ' + empDetail.lastName}}</label>
                    </div>
                </div>
            </div>

            <div class="col-md-2 mt-2">
                <!-- <label class="user_name"> {{empDetail.jobTitle}}</label> -->
                <label class="user_name"> MANAGING DIRECTOR</label>
                <!-- <label class="user_role">{{ empDetail.department }}</label> -->
                <label class="user_role">API MANAGEMENT AND INTEGRATION</label>
            </div>

            <div class="col-md-1 mt-4">
                <div class="row">
                    <div class="col-md-12">
                        <label class="user_id">FG:0104</label>
                    </div>
                </div>
            </div>

            <div class="col-md-2 mt-4">
                <label class="user_email ">{{empDetail.email}}</label>
            </div>

            <div class="col-md-2  mt-1">
                <label class="user_number ml-5 mt-4">{{empDetail.mobileNumber}}</label>
            </div>

            <div class=" mt-2">
                <label class="user_year "> 4 Years ago</label>
                <!-- <label class="user_date " *ngIf="empDetail.employeeId != null ">{{empDetail.joiningDate | date:
                    "dd/MMM/yyyy" }}</label> -->
                <label class="user_date ">22/JUN/2012</label>

            </div>

            <div class="col-md-2 add_And_delet-btn mt-3" *ngIf="hasRoleBased">
                <label class="grid_number" (click)="deleteEmployee(empDetail)">
                    <mat-icon class="delete_icon mt-2">delete_forever</mat-icon>
                    <span class="delete_name_list  ">Delete</span>
                </label>
                <label class="grid_number ml-4" (click)="editEmployeeDialog(empDetail)">
                    <mat-icon class="edit_icon mt-2">edit</mat-icon>
                    <span class="edit_name_list  ">Edit</span>
                </label>
            </div>

            <div class="mt-2 " *ngIf="!hasRoleBased">
                <button class="user-btn ml-4" [matMenuTriggerFor]="menu">
                    <span>
                        Connect
                    </span>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item class="slack" (click)="slackConnect(empDetail)"> Slack </button>
                    <button mat-menu-item class="teams" (click)="teamsConnect(empDetail)"> Teams </button>
                </mat-menu>
            </div>
        </mat-card>
    </div>
</div>
<!-- employee list view starts -->


<!-- employee card view starts -->

<div class="cards ">
    <div class=" ml-3 mt-3" *ngIf="hasRoleBased">
        <mat-card class="grid-card" *ngIf="display === 'grid'">
            <div class="circle">
                <img class="grid_card_add_img" src="assets/add.png" alt="">
            </div>

            <button class="add_Emp_btn_grid " (click)="addEmployeeDialog()"> <span class="add_em_name">+ Add
                    Employee</span> </button>
        </mat-card>
    </div>

    <div class=" ml-3 mt-3" *ngFor="let empDetail of userDto">
        <mat-card class="grid-card" *ngIf="display === 'grid'">
            <div>
                <img class="mat_card_logo_img" src="assets/female.png" alt="">
            </div>
            <div>
                <label class="grid_name">{{empDetail.firstName + " " + empDetail.lastName}} </label>
            </div>
            <div>
                <label class="grid_position">FG:0104 | {{empDetail.jobTitle}} </label>
            </div>
            <div>
                <!-- <label class="grid_role"> {{empDetail.department}}</label> -->
                <label class="grid_role">Api Management and Integration</label>
            </div>
            <hr>
            <div class="mt-2">
                <label class="grid_date">{{empDetail.createdDate | date:
                    "dd/MMM/yyyy" }} | 4 Years ago</label>
            </div>
            <hr>
            <div>
                <label class="grid_number">
                    <mat-icon class="phone_icon   mr-2">phone</mat-icon> <span
                        class=" mr-5">{{empDetail.mobileNumber}}</span>
                </label>
            </div>
            <hr>


            <div class="mr-4">
                <label class="grid_email">
                    <mat-icon class="email_icon mr-2">email</mat-icon>
                    <span class="mt-1">{{empDetail.email}}</span>
                </label>
            </div>
            <hr>

            <div class="connect_btn" *ngIf="!hasRoleBased">
                <button class="grid_user-btn " [matMenuTriggerFor]="menu">
                    <span>
                        Connect
                    </span>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item class="slack" (click)="slackConnect(empDetail)"> Slack </button>
                    <button mat-menu-item class="teams" (click)="teamsConnect(empDetail)"> Teams </button>
                </mat-menu>
            </div>

            <div class="add_And_delet-btn" *ngIf="hasRoleBased">
                <label class="grid_number" (click)="deleteEmployee(empDetail)">
                    <mat-icon class="delete_icon mt-2">delete_forever</mat-icon> 
                    <span class="delete_name_card  ">Delete
                     <span class="ml-3"> | </span></span>
                </label>
                <label class="grid_number ml-4" (click)="editEmployeeDialog(empDetail)">
                    <mat-icon class="edit_icon mt-2">edit</mat-icon>
                    <span class="edit_name_card ">Edit</span>
                </label>
            </div>
        </mat-card>
    </div>
</div>

<!-- employee card view starts -->