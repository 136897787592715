import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feedback-form-generation',
  templateUrl: './feedback-form-generation.component.html',
  styleUrls: ['./feedback-form-generation.component.scss']
})
export class FeedbackFormGenerationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
