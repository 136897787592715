import { Component, OnInit } from '@angular/core';
import { AttendanceService } from '../../services/attendance-extra/attendance.service';
import { AttendanceStatus } from '../../services/attendance-extra/attendance-enum';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { Attendance } from 'src/app/models/attendance';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { element } from 'protractor';

import { dateInputsHaveChanged } from '@angular/material/datepicker/datepicker-input-base';
import { now } from 'moment-timezone';
import { timeStamp } from 'console';
import { getLocaleDateFormat } from '@angular/common';


@Component({
  selector: 'app-login-button',
  templateUrl: './login-button.component.html',
  styleUrls: ['./login-button.component.css']
})
export class LoginButtonComponent implements OnInit {
  data: Attendance = new Attendance();

  disableState: boolean = false;
  attendanceStatus: boolean = false;
  constructor(private attendanceService: AttendanceService,
    private userDetailsService: UserDetailsService,
    private notificationService: NotificationService) { }


  ngOnInit(): void {
    this.checkAttendanceStatus()
  }

  checkAttendanceStatus() {
    this.attendanceService.getAttendanceForLoggerInUser().subscribe(res => {
      this.disableState = res.body.length != 0 ? true : false
      console.log(res.body)
      console.log(res.body.length)
    });

  }

  markAttendance() {
    this.checkAttendanceStatus()
    if (!this.disableState) {
      this.data.createPayload(new Date(), AttendanceStatus.PRESENT, this.userDetailsService.getUserDetails().id)
      this.attendanceService.SendAttendance(this.data).subscribe(res => {
        console.log('working');
        this.notificationService.sucess("Your Attendance is marked Successfully")

      })
    }
  }


}