import { Component, OnInit } from '@angular/core';
import { ProjectURLService } from 'src/app/services/restAPI/projectURLs/project-url.service';
import { RestAPIService } from 'src/app/services/restAPI/rest-api.service';

@Component({
  selector: 'app-feedback-generation',
  templateUrl: './feedback-generation.component.html',
  styleUrls: ['./feedback-generation.component.scss']
})
export class FeedbackGenerationComponent implements OnInit {

  projects = [];

  constructor(private projectURLService: ProjectURLService,private restAPIService: RestAPIService) { }

  ngOnInit(): void {
    this.getAllProjects();
  }

  getAllProjects(){
    this.restAPIService.get(this.projectURLService.getBaseUrl()).subscribe(res=>{
      this.projects = res.body;
    });
  }

}
