<div class="row ">
    <div *ngIf="this.targetValue != undefined" class="col text-left">{{this.progressValue}}/{{this.targetValue}}%</div>
    <div class="col text-right">{{this.lastUpdatedStatus}}</div>
</div>
<div class="row">

    <div class="col">
        <div class="overallProgressBar">

            <div class="progress">
                <div class="progress-bar bg-success" role="progressbar" [style.width]="this.progressValue+'%'" aria-valuenow="10"
                    aria-valuemin="0" aria-valuemax="100">{{this.progressValue}}% {{this.goalStatusLabelRef[goalStatus]}}</div>
            </div>
        </div>
    </div>
</div>