<mat-card class="main-card pl-0 pr-0 mpt-0">
    <table class="table table-hover mpt-0">
      <thead>
        <tr>
          <th class="head">Leave Summary</th>
          <th scope="col" class="balance">Balance</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Loss of Pay</td>
          <td class="num">0</td>
        </tr>
        <tr>
          <td>Unplanned Leave</td>
          <td class="num">1.5</td>
        </tr>
        <tr>
          <td>Paid Leave</td>
          <td class="num">1.5</td>
        </tr>
        <tr>
          <td>Contingency Leave</td>
          <td class="num">5</td>
        </tr>
      </tbody>
    </table>
  </mat-card>
