import { Component, Input, OnInit } from '@angular/core';
import { User } from 'src/app/models/user';
import { AttendanceUrlService } from 'src/app/services/attendanceURLs/attendance-url.service';
import { AttendanceStatus } from 'src/app/services/attendance copy/attendance-enum';
import { AttendanceService } from 'src/app/services/attendance copy/attendance.service';
import { RestAPIService } from 'src/app/services/restAPI/rest-api.service';
import { UitoggleService } from 'src/app/services/uitoggle.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-employee-unmarked',
  templateUrl: './employee-unmarked.component.html',
  styleUrls: ['./employee-unmarked.component.scss']
})
export class EmployeeUnmarkedComponent implements OnInit {
  @Input() public index;
  @Input() data
  userDto:User[] =[];
  
  display:string
  dataSource: any;
  date= [String(new Date().getDate()).padStart(2,'0'),(String(new Date().getMonth() + 1)).padStart(2,'0'),(new Date().getFullYear())].join('-')



  constructor(private restApi:RestAPIService,
   private attendanceUrl:AttendanceUrlService,

    public userService: UserService,
    private uitoggle:UitoggleService,public attendanceService:AttendanceService) { }

  ngOnInit(): void {
    this.uitoggle.cast.subscribe(data => this.display=data);
    this.attendanceService.getAttendance(AttendanceStatus.ABSENT,this.date).subscribe((response)=> { 
      (response.body).forEach((data) => {
        this.userDto.push(new User(data));
      })

    })
}

getAttendance(attendanceStatus,date){
  return this.restApi.get(
    this.attendanceUrl.getAttendanceUrl(attendanceStatus,date)
  )
}


  
}
