import { Injectable } from '@angular/core';
import { OneOnOneMeeting } from 'src/app/models/oneOnOneMeeting';
import { OneOnOneMeetingUrlService } from '../restAPI/oneOnOneMeetingURLs/one-on-one-meeting-url.service';
import { RestAPIService } from '../restAPI/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class OneOnOneMeetingService {

  careerManager;
  oneOneOneMeetingList:OneOnOneMeeting[]=[];
  constructor(private restAPI: RestAPIService,
    private oneOnOneMeetingUrl: OneOnOneMeetingUrlService) { }

  getAllOneOnOneMeetingSubscribe() {
    return this.restAPI.get(
      this.oneOnOneMeetingUrl.getBaseUrl()
    )
  }

  getAllOneOnOneMeeting() {
    this.restAPI.get(
      this.oneOnOneMeetingUrl.getBaseUrl()
    )
  }

  getOneOnOneMeetingByIdSubscribe(id){
    return this.restAPI.get(
      this.oneOnOneMeetingUrl.getByFieldUrl(id)
    )
  }


  setOneOnOneMeetingList(data){
    this.oneOneOneMeetingList = []
    for(let singleData of data){
      this.oneOneOneMeetingList.push(new OneOnOneMeeting(singleData))
    }
  }

  

  postOneOnOneMeeting(payload) {
    this.restAPI.post(
      this.oneOnOneMeetingUrl.getBaseUrl(),
      payload
    ).subscribe()
  }

  postOneOnOneMeetingSubcribe(payload) {
    return this.restAPI.post(
      this.oneOnOneMeetingUrl.getBaseUrl(),
      payload
    )
  }



}
