export class FormSchemaDTO {
    public  id : String;
    public formTemplate : String;
    public projectId : String;
    public formType : String;
    public careerManagerId:string;

    constructor(){
        this.id = "";
        this.formTemplate = "";
        this.projectId = "";
        this.formType = "";
        this.careerManagerId = "";
    }
}
