import { ReviewStatus } from '../services/employeeReview/employeeReview-enum';


interface IOneOnOneMeeting {
    id?: string;
    directReporteeId?: string
    meetingDate?: Date
    meetingSummary?: string
    status?: ReviewStatus
    careerManagerId?:string

}

export class OneOnOneMeeting {
    id: string;
    directReporteeId: string
    meetingDate: Date
    meetingSummary: string
    status: ReviewStatus
    careerManagerId:string

    constructor(employeeReview: IOneOnOneMeeting) {
        if (employeeReview && employeeReview.id) {
            this.id = employeeReview.id;
        }
        this.directReporteeId = employeeReview && employeeReview.directReporteeId || null
        this.meetingDate = employeeReview && employeeReview.meetingDate || null
        this.meetingSummary = employeeReview && employeeReview.meetingSummary || null
        this.status = employeeReview && employeeReview.status || null
        this.careerManagerId = employeeReview && employeeReview.careerManagerId || null
      
      
    }
}