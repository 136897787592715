<div class="container-fluid mt-4">
    <div class="row">
        <div class="col-md-12">
            <button   class="close-button"  mat-button (click)="matDialogClose()">Close</button>
        </div>
    </div>
    <div class="row  main_container">
        <div class="col-md-12">
            <h1 class="confirmation_text">Are u sure to detele an employee??</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="main_button_div">
                <button mat-button  class="yes_button" (click)="deleteEmployee()">YES</button>
                <button mat-button  class="no_button ml-3" (click)="matDialogClose()">No</button>
            </div>
        </div>
    </div>
</div>
