<p>feedback-forms works!</p>
<mat-accordion>
    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Select Project
            </mat-panel-title>
            <mat-panel-description>
                Create Feedback Forms
                <mat-icon>feed</mat-icon>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="form-group">
            <mat-form-field class="example-full-width">
                <input matInput placeholder="Select Project" type="text" class="form-control"
                    [formControl]="feedbackIdFormControl" [matAutocomplete]="auto" name="projectId"
                    (ngModelChange)="onProjectIdChange($event)" [(ngModel)]="feedbackForm.projectId" required>
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn.bind(this)">
                    <mat-option *ngFor="let option of filteredOptions | async" [value]="option.id">
                        {{option.projectName}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
    <div *ngIf="isProject">
        <div class="form-group">
            <mat-action-row>
                <button class="btn btn-success" style="margin: 5px; float: left;"
                    (click)="openDialog()">Create Forms <i class="pl-2 fas fa-plus"></i></button>
                <!-- <button class="btn btn-primary" style="margin: 5px;" (click)="viewFormsDialog()">View
                    Forms <i class="fas fa-eye"></i></button> -->
                <button class="btn btn-warning" style="margin: 5px;" (click)="editDialog()">Edit
                    Forms <i class="fas fa-edit"></i></button>
            </mat-action-row>
        </div>
        <mat-action-row>
            <button class="btn btn-primary" (click)="saveForm(feedbackForm)">Save <i
                    class="far fa-save"></i></button>
        </mat-action-row>
    </div>
        
    </mat-expansion-panel> 
</mat-accordion>