<div class="row">
    <div class="col-12 ">
        <div class="row">
            <div class="col-12">
                <div class="header">PULSE</div>
            </div>
        </div>
        <div class="login-bg">
            <div class="container-fluid text-center login-form">
                <div class="row align-items-center">
                    <div class="col-12">
                        <span id="rect"> <img src="\assets\Rectangle 33.png"></span>
                        <div class="h3 mb-3 mt-4 text-left login">Log-in</div>
                        <form [formGroup]="loginPasswordFormGroup" (ngSubmit)="onClickLoginGetToken()" class="row">
                            <div class="col-12">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>Email</mat-label>
                                    <input matInput type="text" formControlName="email" autocomplete="off" />
                                    <mat-error
                                        *ngIf="!loginPasswordFormGroup.controls['email'].valid && loginPasswordFormGroup.controls['email'].touched">
                                        Email is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <mat-form-field class="full-width" appearance="outline">
                                    <mat-label>Password</mat-label>
                                    <input matInput [type]="hide ? 'password' : 'text'" formControlName="password"
                                        autocomplete="off">
                                    <button mat-icon-button matSuffix type="button" (click)="hide = !hide"
                                        [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                        <mat-icon Pass through>{{hide ? 'visibility_off' : 'visibility'}}
                                        </mat-icon>
                                    </button>
                                    <mat-error
                                        *ngIf="!loginPasswordFormGroup.controls['password'].valid && loginPasswordFormGroup.controls['password'].touched">
                                        Password is required
                                    </mat-error>
                                </mat-form-field>
                                <div class="forgotPassword">
                                    <a href="">Forgot your password?</a>
                                </div>
                            </div>

                            <div class="col-12 pt-4">
                                <button mat-raised-button color="primary" type="submit" class="login-sign-up-button">
                                    Log In
                                </button>

                            </div>
                        </form>
                        <div class="col-12 pt-2 text-center mt-2 mb-3">
                            <label class="mb-0 label-s">SignIn</label><br>
                            <a class="signin" (click)="signIn()">
                                Sign-in with Microsoft
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-spinner></app-spinner>