import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormSchemaUrlService {
  
  

  //url format used /<url>

  private readonly formSchemaBaseApi = '/api/form-schemas';
  private readonly careerManagerTeam = '/career-manager-team';
  private readonly project = '/project';
  private readonly search = '/search';
  private readonly formSchemaByCareerManagerTeamId = '/career-manager-team';
  

  constructor() { 
    
  }

  getBaseUrl(){
    return this.formSchemaBaseApi;
  }

  getPaginationUrl(pageCount,pageSize){
    pageCount-=1;
    return this.formSchemaBaseApi+'?'+'page='+pageCount+'&size='+pageSize;
  }

  getCareerManagerByIdUrl(id){
    return this.formSchemaBaseApi+this.careerManagerTeam+this.getByField(id);
  }

  getProjectByIdUrl(id){
    return this.formSchemaBaseApi+this.project+this.getByField(id);
  }

  //This will be used for get by id and delete by id
  getByFieldUrl(field){
    return this.formSchemaBaseApi+ this.getByField(field)
  }

  getSearchUrl(searchText: string) {
    return this.formSchemaBaseApi+this.search+this.getByField(searchText)
  }

  private getByField(field){
    return '/'+field;
  }

  getFormSchemaByCareerManagerTeamId(id,formType){
    return this.formSchemaBaseApi+this.formSchemaByCareerManagerTeamId+ this.getByField(id) + this.getByField(formType);
  }

  getFormSchemaByProjectIdAndFormType(id,formType:String){
    return this.formSchemaBaseApi + this.project + this.getByField(id) + this.getByField(formType)
  }

  getFormSchemaByCareerManagerIdAndFormType(id,formType:String){
    return this.formSchemaBaseApi + this.careerManagerTeam + this.getByField(id) + this.getByField(formType)
  }

  getDeleteUrl(id: any): any {
    return this.formSchemaBaseApi + this.getByField(id)
  }
}
