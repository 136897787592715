import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { User } from 'src/app/models/user';
import { environment } from 'src/environments/environment';
import { InitialAvatarService } from '../initialAvatar/initial-avatar.service';
import { RestAPIService } from '../restAPI/rest-api.service';
import { UserUrlService } from '../restAPI/userURLs/user-url.service';
@Injectable({
  providedIn: 'root'
})
export class UserService {
  public users = [];
  public usersDto: User[];
  public directReportees:User[];
  public userName;

  public allUserData = [];

  
  constructor(private restApi: RestAPIService,
    private userUrl: UserUrlService, private http: HttpClient,
    private initialAvatarService:InitialAvatarService) { }


  getAllUser():any {
    this.restApi.get(
      this.userUrl.getBaseUrl()+"?size=200"
    ).subscribe(res => {
      this.setAllUsers(res.body);
    })
  }

  validateEmail(email) {
   return  this.restApi.get(this.userUrl.getValidateEmailUrl(email));
  }

  validateEmployeeId(employeeId) {
    return  this.restApi.get(this.userUrl.getValidateEmployeeIdUrl(employeeId));
  }

  setAllUsers(users) {
    this.allUserData = [];
    for (let user of users) {
      this.allUserData.push(user);
      // console.log(this.allUserData);
    }
  }

  getUser() {
    this.restApi.get(
      this.userUrl.getBaseUrl()
    ).subscribe(res => {
      this.setUsers(res.body);
      this.setUsersDto(res.body)
      this.allUserData
    })
  }
  getUserSubscribe() {
    return this.restApi.get(
      this.userUrl.getBaseUrl()
    )
  }
  setUsers(users) {
    this.users = [];
    for (let user of users) {
      this.users.push(user);
    }
  }

  setUsersDto(users) {
    this.usersDto = [];
    for (let user of users) {
      this.usersDto.push(new User(user))
    }
  }

  getUserByActivatedStatus(activated:boolean){
    this.restApi.get(
      this.userUrl.getUserByActivatedStatusUrl(activated)
    ).subscribe(res => {
      this.setUsers(res.body);
      this.setUsersDto(res.body);
    })
  }

  getUserByActivatedStatusSubscribe(activated:boolean){
    return this.restApi.get(
      this.userUrl.getUserByActivatedStatusUrl(activated)
    )
  }

  getUserByID(id) {

    return this.restApi.get(this.userUrl.getByFieldUrl(id))
  }

  getUserNameByID(id): Observable<any> {
    return this.http.get(environment.BASE_API_URL_BACKEND+'/api/users/userName/' + id, { responseType: 'text' });
  }

  searchUser(searchText){
    let temp = this.userUrl.getSearchUrl(searchText)
    this.restApi.get(
      this.userUrl.getSearchUrl(searchText)
    ).subscribe(res=>{
      this.setUsersDto(res.body)
    })
  }

  setDirectReportees(directReportees){
    this.directReportees = [];
    for (let user of directReportees) {
      this.directReportees.push(new User(user))
    }
  }

  getAllDirectReportees(careerManagerId){

    this.restApi.get(
      this.userUrl.getDirectReportees(careerManagerId)
    )
    .subscribe(res=>{
      //direct reportees are userDto process similar to set users
      this.setDirectReportees(res.body)
    })

  }

  getAllDirectReporteesSubscribe(careerManagerId){
    return this.restApi.get(
      this.userUrl.getDirectReportees(careerManagerId)
    )
  }

  addUser(userDto){
    debugger
    console.log(userDto)
    return this.restApi.post(
      this.userUrl.getBaseUrl(),
      userDto
    )
  }

  deleteUser(id){
    debugger;
    this.restApi.delete(
      this.userUrl.getDeleteUrl(id)
    ).subscribe(res=>{
      this.getUser()
    })
  }

  updateUser(payload){
    return this.restApi.put(
      this.userUrl.getBaseUrl(),
      payload
    )
  }

  processName(user) {
    let name;
    if (user && user.firstName)
      name = user.firstName

    if (user && user.lastName) {
      name ? name += " " + user.lastName : name = user.lastName;
    }

    return name;
  }

  // processLogo(user){
  //   return this.initialAvatarService.getInitialImage(
  //     this.processName(user)
  //   )
  // }
}



