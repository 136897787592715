import { Component, Input, OnInit } from '@angular/core';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-participant-card',
  templateUrl: './participant-card.component.html',
  styleUrls: ['./participant-card.component.scss']
})
export class ParticipantCardComponent implements OnInit {

  @Input() user;
  constructor(public userService:UserService,
              public initialAvatarService:InitialAvatarService) { }

  ngOnInit(): void {
    console.log(this.user)
  }

  

}
