interface IClient {
    id?: string;
    clientLocation?: string;
    clientName?: string;
    clientDescription?: string;
    createdDate?: Date;
    imageId?: string;
}

export class Client {
    id: string;
    clientLocation: string;
    clientName: string;
    clientDescription: string;
    createdDate: Date;
    imageId: string;
    constructor(client: IClient) {
        if (client && client.id) {
            this.id = client.id;
        }
        this.clientLocation = client && client.clientLocation || null;
        this.clientName = client && client.clientName || null;
        this.clientDescription = client && client.clientDescription || null;
        this.createdDate = client && client.createdDate || new Date();
        this.imageId = client && client.imageId || null;
    }
}