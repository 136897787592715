import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { CareerManagerDTO } from '../dtos/CareerManagerDTO';
import { CareerManagerTeam } from '../models/careerManagerTeam';
import { ApiService } from './api.service';
import { CareermanagerUrlService } from './restAPI/careerManagerURLs/careermanager-url.service';
import { FormSchemaUrlService } from './restAPI/formSchemaURLs/form-schema-url.service';
import { RestAPIService } from './restAPI/rest-api.service';
@Injectable({
  providedIn: 'root'
})
export class CareerManagerTeamService {
  
  totalCount;
  page:PageEvent
  pageCount=1
  pageSize=8
  careerManagerTeam = [];
  singleCareerManagerTeamData;
  careerManagerDto:CareerManagerDTO = new CareerManagerDTO();
  constructor(private apiService: ApiService, private restAPIService: RestAPIService, private careermanagerUrlService: CareermanagerUrlService,
    private formSchemaUrlService: FormSchemaUrlService) { }
  getCareerManagerTeamPagination(){
    this.restAPIService.get(
      this.careermanagerUrlService.getPaginationUrl(this.pageCount,this.pageSize)
    ).subscribe(res=>{
      this.setCareerManagerTeam(res.body);
      this.totalCount=Number(res.headers.getAll('X-Total-Count'));
    })
  }
  setCareerManagerTeam(careerManagerTeams){
    this.careerManagerTeam = [];
    for(let careerManagerTeam of careerManagerTeams){
      this.careerManagerTeam.push(new CareerManagerTeam(careerManagerTeam));
    }
  }

  setSingleCareerManagerTeam(careerManagerTeam){
    this.singleCareerManagerTeamData = new CareerManagerTeam(careerManagerTeam)
  }

  processCareerManagerResposnseBody(careerManagerTeam){
    return new CareerManagerTeam(careerManagerTeam);
  }
  getCareerManagerById(id){
    this.restAPIService.get(
      this.careermanagerUrlService.getByFieldUrl(id)
    ).subscribe(res =>{
    })
  }

  getCareerManagerByIdSubscribe(id){
    return this.restAPIService.get(
      this.careermanagerUrlService.getByFieldUrl(id)
    )
  }
  getCareerManagerData(){
   this.restAPIService.get(this.careermanagerUrlService.getBaseUrl()).subscribe();
  }
  getCareerManagerDataById(id){
   return this.restAPIService.get(this.careermanagerUrlService.getByFieldUrl(id));
  }
  postCareerManagerData(careerManagerData){
   return this.restAPIService.post(this.careermanagerUrlService.getBaseUrl(), careerManagerData);
  }
  postCareerManagerFormData(careerManagerFormData){
    return this.restAPIService.post(this.formSchemaUrlService.getBaseUrl(), {
      "formTemplate": careerManagerFormData.formTemplate,
      "careerManagerTeamId": careerManagerFormData.careerManagerTeamId,
      "formType" : careerManagerFormData.formType,
    });
  }
  putCareerManagerTeamData(careerManagerTeamData){
   this.restAPIService.put(this.careermanagerUrlService.getBaseUrl(), careerManagerTeamData).subscribe();
  }
  getFeedbackFormByCareerManagerTeamId(CareerManagerTeamId){
    return this.restAPIService.get(this.formSchemaUrlService.getCareerManagerByIdUrl(CareerManagerTeamId))
  }

  searchClientByText(event) {
    this.restAPIService.get(
      this.careermanagerUrlService.getSearchUrl(event)
    ).subscribe(res => {
      this.setCareerManagerTeam(res.body);
    });
  }

  checkIfCareerManagerExistsSubscribe(id) {
    return this.restAPIService.get(
      this.careermanagerUrlService.getIfCareerManagerExistsUrl(id)
    )
  }

  // processCareerManagerToDto(careerManager:CareerManagerTeam){
  //   this.careerManagerDto.id = careerManager.id;
  //   this.careerManagerDto.careerManagerId =  
  // }
}
