import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClientURLService {

 
  private readonly clientBaseApi = '/api/clients'
  private readonly search="/search"

  constructor() { 
    
  }

  getBaseUrl(){
    return this.clientBaseApi;
  }

  getPaginationUrl(pageCount,pageSize){
    pageCount-=1;
    return this.clientBaseApi+'?'+'page='+pageCount+'&size='+pageSize;
  }

  getSearchUrl(searchText){

    return this.clientBaseApi+this.search+this.getByField(searchText);
  }

  getByFieldUrl(field){
    return this.clientBaseApi+ this.getByField(field)
  }

  private getByField(field){
    return '/'+field;
  }
}
