import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UitoggleService {

  public state = new BehaviorSubject<string>('list');
  cast =this.state.asObservable();



  constructor() { }

   changestate(data){
     this.state.next(data);

   }
}
