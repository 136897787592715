        <mat-nav-list style="position: relative; " >
            <mat-list-item>
                <mat-icon mat-list-icon>  <img class="logo-img"  src="/assets/pulse-logo.png" alt=""></mat-icon>
                <span class="pulse-name">PULSE</span>
            </mat-list-item>

      

            <mat-list-item>
                <mat-icon mat-list-icon>   <img id="jim" src="/assets/female.png" alt=""></mat-icon>
                <span class="full-width namingList mt-3 ">{{adminName}}</span>
            </mat-list-item>
        
            <mat-divider></mat-divider>
        
            <div class="mat-list-scollable" > 
                <mat-list-item  class="parent " *ngFor="let page of this.loginService.pages" [matTooltip]="page.tooltip" [routerLink]="page?.link">
                        <mat-icon mat-list-icon class="ml-2">{{page?.icon}}</mat-icon>
                        <span  class="full-width namingList mt-3">{{ page?.name }} </span>
                </mat-list-item>
            </div>
        
        
            <mat-divider></mat-divider>
        
            <mat-list-item class="parent " (click)="logOut()" >
                <mat-icon mat-list-icon class="ml-2 logoutstyle" >logout</mat-icon>
                <span  class="full-width logouttext mt-1">Logout </span>
            </mat-list-item>
        </mat-nav-list>
