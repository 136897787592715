<mat-card class="main-card">
    <table class="table">
      <thead>
        <tr>
          <th class="head">My Team</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let hero of empDetail; let i = index">
          <td><img class="img" matListAvatar src="assets/female.png" alt="...">
            &nbsp; {{hero.firstName}}&nbsp;{{hero.lastName}}</td>
        </tr>
      </tbody>
    </table>
  </mat-card>