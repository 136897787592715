import { Injectable } from '@angular/core';
import { EvaluationConfigurationURLsService } from '../restAPI/evaluationConfigurationURLs/evaluation-configuration-urls.service';
import { RestAPIService } from '../restAPI/rest-api.service';
import { SpinnerService } from '../spinner.service';

@Injectable({
  providedIn: 'root'
})
export class EvaluationConfigurationService {

  constructor(private restApiService: RestAPIService, private spinnerService:SpinnerService,
    private evaluationUrlService: EvaluationConfigurationURLsService) { }


  getEvaluationAllConfigurationSubscribe() {
    return this.restApiService.get(
      this.evaluationUrlService.getBaseUrl()
    )
  }

  getEvaluationConfigurationByIdSubscribe(id) {
    return this.restApiService.get(
      this.evaluationUrlService.getByFieldUrl(id)
    )
  }

  postEvaluationConfigurationSubscribe(payload) {
    this.spinnerService.requestStarted()
    return this.restApiService.post(
      this.evaluationUrlService.getBaseUrl(),
      payload
    )
  }

  UpdateEvaluationConfigurationSubscribe(payload) {
    return this.restApiService.put(
      this.evaluationUrlService.getBaseUrl(),
      payload
    )
  }
  
  deleteEvaluationConfigurationSubscribe(id) {
    return this.restApiService.delete(
      this.evaluationUrlService.getByFieldUrl(id)
    )
  }



}
