import { UserDTO } from '../dtos/UserDTO';
import { ReviewStatus } from '../services/employeeReview/employeeReview-enum';
import { Client } from './client';
import { User } from './user';

interface IEmployeeReview {
    id?: string;
    careerManagerId?:string
    dueDate?:Date
    employeeId?:string
    oneOnOneMeetingId?:string
    reviewCycle?:string
    reviewStatus?:ReviewStatus
    reviewType?:string
    employee?:User

}

export class EmployeeReview {
    id: string;
    careerManagerId:string
    dueDate:Date
    employeeId:string
    oneOnOneMeetingId:string
    reviewCycle:string
    reviewStatus:ReviewStatus
    reviewType:string
    employee:User
    constructor(employeeReview: IEmployeeReview) {
        if (employeeReview && employeeReview.id) {
            this.id = employeeReview.id;
        }
        this.careerManagerId = employeeReview && employeeReview.careerManagerId || null
        this.dueDate = employeeReview && employeeReview.dueDate || null
        this.employeeId = employeeReview && employeeReview.employeeId || null
        this.oneOnOneMeetingId = employeeReview && employeeReview.oneOnOneMeetingId || null
        this.reviewCycle = employeeReview && employeeReview.reviewCycle || null
        this.reviewType = employeeReview && employeeReview.reviewType || null
        this.reviewStatus = employeeReview && employeeReview.reviewStatus || null
        this.employee = employeeReview && employeeReview.employee || null
    }
}