import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, AbstractControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserDTO } from 'src/app/dtos/UserDTO';
import { User } from 'src/app/models/user';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { AuthoritiesConstants, Departments, JobTitles } from 'src/app/services/user/user-enum';
import { ProjectService } from 'src/app/services/project.service';
import { CareerManagerTeamService } from 'src/app/services/careerManager.service';
import { CareerManagerDTO } from 'src/app/dtos/CareerManagerDTO';
import { CareerManagerTeam } from 'src/app/models/careerManagerTeam';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { Title } from '@angular/platform-browser';
import { TitleService } from 'src/app/services/title-service';

@Component({
  selector: 'app-add-employee-card',
  templateUrl: './add-employee-card.component.html',
  styleUrls: ['./add-employee-card.component.scss']
})
export class AddEmployeeCardComponent implements OnInit {

  newEmployeeDetails: FormGroup;
  userDto = new UserDTO();
  jobTitles = [];
  departments = [];
  careerManagerDto: CareerManagerDTO = new CareerManagerDTO();
  careerManagerTeam: CareerManagerTeam;
  filteredCareerManager: Observable<any[]>;
  rolesList: string[] = [];
  authoritiesEnumRef: AuthoritiesConstants;
  email: AbstractControl;

  emailError: string
  disbleInputField: boolean

  documentsToUpload: Array<File>;

  photo: any;

  base64textString :any;
  imageError: string;
  binaryImgString : string;
  allTitleList = [];

  constructor(
    public userService: UserService,
    public userDetailService: UserDetailsService,
    public fb: FormBuilder,
    private notificationService: NotificationService,
    public projectService: ProjectService,
    public careerManagerService: CareerManagerTeamService,
    private titleService: TitleService,
    public dialogRef: MatDialogRef<AddEmployeeCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User) {

    this.newEmployeeDetails = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      employeeId: ['', [Validators.required]],
      department: ['', [Validators.required]],
      email: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])],
      mobileNo: ['', Validators.compose([Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")])],
      jobTitle: ['', [Validators.required]],
      careerManager: [''],
      dateOfJoining: ['', [Validators.required]],
      dateOfBirth: ['', [Validators.required]],
      roles: [''],
      probationPeriod: [''],
      confirmationDate: ['',[Validators.required]],
      fileSource:  [''],

    },
    )
  }

  ngOnInit(): void {

    this.getTitleData()
    if (this.userService.allUserData.length == 0) {
      this.userService.getAllUser()
    }

    // Binding Edit Data to Form
    this.setFormData();
    this.processEnumValuesToArray();
    this.projectService.getProjects();
    this.userService.getUser();

    // on carrier manager search
    this.filteredCareerManager = this.newEmployeeDetails.get('careerManager').valueChanges.pipe(
      startWith(''),
      map(state => this.filterManager(state))
    )
  }

  getTitleData() {
    this.titleService.getAllTitleList().subscribe(data => {
      data.body.forEach(item => {
        this.allTitleList.push(item)
      });
      console.log(this.allTitleList);
      debugger
    })
  }

  jobTitleList(data) {
    const probationValue = this.allTitleList.filter(x => x.titleName === data)[0]?.probationPeriod;
    this.newEmployeeDetails.get('probationPeriod').setValue(
      probationValue ? probationValue : ''
    )
  }

  setDefaultImg() {
    this.photo = false;
  }




  checkError = (controlName: string, errorName: string) => {
    return this.newEmployeeDetails.controls[controlName].hasError(errorName);
  }

  processEnumValuesToArray() {
    for (let jobTitle of Object.values(JobTitles)) {
      if (isNaN(Number(jobTitle)))
        this.jobTitles.push(jobTitle)
    }
    for (let authoritieslist of Object.values(AuthoritiesConstants)) {
      if (isNaN(Number(authoritieslist)))
        this.rolesList.push(authoritieslist)
    }

    for (let department of Object.values(Departments)) {
      if (isNaN(Number(department)))
        this.departments.push(department)
    }
  }

  readURL(event: Event): void {
    this.photo = false;
    this.imageError = '';
    if (event && event[0]) {
      const file = event[0];
      const reader = new FileReader();
      const max_size = 20971520;
      const max_height = 15200;
      const max_width = 25600;
      
      if (event[0].size > max_size) {
        this.imageError = 'Maximum size allowed is ' + max_size / 1000 + 'Mb';
      }
      reader.onload = (e) => {
        this.photo = true;
         this.binaryImgString = e.target.result as string;
         this.base64textString= btoa(this.binaryImgString);
        this.newEmployeeDetails.patchValue({
          fileSource: this.base64textString
        });
      }
      reader.readAsDataURL(file);
    }
  }


  onEmailTextTyped(event) {
    console.log(event);
    this.userService.validateEmail(event).subscribe(res => {
      console.log(res);
    },(error) => {
      console.log(error);
      if(error.error.errorKey == "emailexists") {
        this.setFormControlError('email', 'InvalidEmail', 'Email already Exist');
      }else {
        this.setFormControlError('email', 'InvalidEmail', 'required');
      }
    })
  }

  onEmployeeIdTextTyped(event) {
    this.userService.validateEmployeeId(event).subscribe(res => {
    },(error) => {
      if(error.error.errorKey == "employeeIdExist") {
        this.setFormControlError('employeeId', 'InvalidEmployeeId', 'Employee Id Already Exist');
      }else {
        this.setFormControlError('employeeId', 'InvalidEmployeeId', 'required');
      }
    })
  }


  setFormData() {
    if (this.data) {
      this.newEmployeeDetails.get('firstName').setValue(
        this.data.firstName ? this.data.firstName : ''
      )

      this.newEmployeeDetails.get('lastName').setValue(
        this.data.lastName ? this.data.lastName : ''
      )

      var jobTitle = this.getEnumValueByEnumKey(JobTitles, this.data.jobTitle);
      this.newEmployeeDetails.get('jobTitle').setValue(
        this.data.jobTitle ? jobTitle : ''
      )

      var department = this.getEnumValueByEnumKey(Departments, this.data.department);
      this.newEmployeeDetails.get('department').setValue(
        this.data.department ? department : ''
      )

      var tempauthorities = this.getauthorityValueByEnumKeyArray(AuthoritiesConstants, this.data.authorities)
      this.newEmployeeDetails.get('roles').setValue(
        this.data.authorities ? tempauthorities : ''
      )
        
      this.newEmployeeDetails.get('email').setValue(
        this.data.email ? this.data.email : ''
      )

      this.newEmployeeDetails.get('mobileNo').setValue(
        this.data.mobileNumber ? this.data.mobileNumber : ''
      )

      if (this.data.careerManagerId) {
        this.userService.getUserByID(this.data.careerManagerId).subscribe(res => {
          this.newEmployeeDetails.get('careerManager').setValue(new User(res.body))
        })
      }

      this.newEmployeeDetails.get('employeeId').setValue(
        this.data.employeeId ? this.data.employeeId : ''
      )

      this.newEmployeeDetails.get('dateOfJoining').setValue(
        this.data.joiningDate ? new Date(this.data.joiningDate) : ''
      )
    }
  }

  onSubmit() {
    if (this.newEmployeeDetails.valid) {
      this.userDto.activated = false;
      this.userDto.createdBy = this.userDetailService.getUserDetails().id
      this.userDto.createdDate = new Date()
      this.userDto.lastModifiedBy = this.userDto.createdBy
      this.userDto.lastModifiedDate = this.userDto.lastModifiedDate
      this.userDto.firstName = this.newEmployeeDetails.get('firstName').value
      this.userDto.lastName = this.newEmployeeDetails.get('lastName').value
      this.userDto.email = this.newEmployeeDetails.get('email').value
      this.userDto.jobTitle = this.getEnumKeyByEnumValue(JobTitles, this.newEmployeeDetails.get('jobTitle').value);
      this.userDto.authorities = this.getauthoritiesKeyByEnumValueArray(AuthoritiesConstants, this.newEmployeeDetails.get('roles').value);
      this.userDto.login = this.userDto.email
      this.userDto.microsoftId = 'asldkfjasdgnak23rafa9'
      this.userDto.mobileNumber = this.newEmployeeDetails.get('mobileNo').value
      this.userDto.langKey = "en"
      this.userDto.careerManagerId = this.newEmployeeDetails.get('careerManager').value.id;
      this.userDto.department = this.getEnumKeyByEnumValue(Departments, this.newEmployeeDetails.get('department').value)
      this.userDto.employeeId = this.newEmployeeDetails.get('employeeId').value
      this.userDto.joiningDate = this.newEmployeeDetails.get('dateOfJoining').value
      this.userDto.probationPeriod = this.newEmployeeDetails.get('probationPeriod').value
      this.userDto.confirmationDate = this.newEmployeeDetails.get('confirmationDate').value
      this.userDto.fileSource = this.newEmployeeDetails.get('fileSource').value
      if (this.data) {
        if (this.data.id) this.userDto.id = this.data.id
        this.userService.updateUser(this.userDto).subscribe(res => {
          this.userService.getUser();
          this.dialogRef.close()
          this.notificationService.sucess("User Data Edited")
        })
      } else {
        this.userService.addUser(this.userDto).subscribe(res => {
          this.userService.getUser();
          this.dialogRef.close()
          this.notificationService.sucess("New User Created")
        })
      }
    } else {
    debugger
      this.setFormControlError('email', 'InvalidEmail', 'required');
      this.setFormControlError('employeeId', 'InvalidEmployeeId', 'required');
      this.notificationService.invalidFormData()
    }
  }

 

  setFormControlError(controlName:string, controlError:string, errorMsg:string) {
    this.newEmployeeDetails.get(controlName).markAsTouched({ onlySelf: true });
    this.newEmployeeDetails.get(controlName).markAsDirty({ onlySelf: true });
    // this.newEmployeeDetails.get(controlName).setErrors({[controlError] : true, [errorMsg]: true });
    this.newEmployeeDetails.get(controlName).setErrors({[controlError] : true });
    this.emailError = errorMsg;
  }

  closeDialog() {
    this.dialogRef.close()
  }

  displayFnCareerManager(user): string {
    return user && (user.firstName || user.lastName) ? user.firstName + ' ' + user.lastName : '';
  }

  filterManager(name: string) {
    if (typeof name !== 'object')
      return this.userService.allUserData.filter(user =>
        user.firstName.toLowerCase().indexOf(name.toLowerCase()) === 0 ||
        user.lastName.toLowerCase().indexOf(name.toLowerCase()) === 0);
    else
      return
  }

  getJobTitle(item) {
    if (isNaN(Number(item))) {
      return item
    }
  }

  getEnumKeyByEnumValue(myEnum, enumValue) {
    let keys = Object.keys(myEnum).filter(x => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }
  // working -----
  getEnumValueByEnumKey(myEnum, enumKey: string) {
    return myEnum[enumKey];
  }

  getauthoritiesKeyByEnumValueArray(myEnum, enumValue) {
    return Object.keys(myEnum).filter(k => enumValue.includes(myEnum[k]));
  }

  getauthorityValueByEnumKeyArray(myEnum, enumKeyArr) {
    return enumKeyArr ? enumKeyArr.map(k => myEnum[k]) : '';
  }

}
