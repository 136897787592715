<div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleIndicators" data-slide-to="0" class="active"
        aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-slide-to="1"
        aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleIndicators" data-slide-to="2"
        aria-label="Slide 3"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img src="../../../assets/backg.png" alt="Notifications">
      </div>
      <div class="carousel-item">
        <img src="../../../assets/fg-entrance.png" alt="news">
      </div>
      <div class="carousel-item">
        <img src="../../../assets/feedback-3.png" alt="news">
      </div>
    </div>
  </div>
