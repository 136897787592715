import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout'
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import {trigger, state, style, animate, transition} from '@angular/animations';
import { UserDetailsService } from 'src/app/services/user-details.service';
@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  animations: [
    // Each unique animation requires its own trigger. The first argument of the trigger function is the name
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-180deg)' })),
      transition('rotated => default', animate('500ms ease-out')),
      transition('default => rotated', animate('500ms ease-in'))
  ])
]
})
export class PagesComponent implements OnInit {
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;

  isExpanded = true;
  showSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;

  isMenuOpen = true;
  contentMargin = 240;
  menuMargin = 0;
  state: string = 'default';

  constructor(
    private observer: BreakpointObserver,
    public userDetatilService: UserDetailsService,
    private _router: Router,
  ) {
  }
  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.observer.observe(['(max-width: 800px)']).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = 'side';
        this.sidenav.open()
      }
      else {
        this.sidenav.mode = 'side';
        this.sidenav.open()
      }
    }),
    this.observer.observe(['(max-width: 768px)']).subscribe((res) => {
      if (res.matches) {
        this.sidenav.mode = 'side';
        this.sidenav.open()
      }
      else {
        this.sidenav.mode = 'side';
        this.sidenav.open()
      }
    })
  }


  onToolbarMenuToggle() {
    console.log(this.isMenuOpen)
    this.isMenuOpen = !this.isMenuOpen;

    if (!this.isMenuOpen) {
      this.contentMargin = 60;
      this.menuMargin = 10;
      this.state = (this.state === 'default' ? 'rotated' : 'default');

    } else {
      this.contentMargin = 240;
      this.menuMargin = 0;
      this.state = (this.state === 'default' ? 'rotated' : 'default');
    }
  }

}
