import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-upcoming-events',
  templateUrl: './upcoming-events.component.html',
  styleUrls: ['./upcoming-events.component.scss']
})
export class UpcomingEventsComponent implements OnInit {

  empDetail=[];
  Birthdaylist =[];
  Anniversarylist =[];
  ngOnInit():void{
    this.userService.getUserSubscribe().subscribe(res => {
      this.userService.setUsersDto(res.body);
      this.empDetail = this.userService.usersDto;
      console.log(this.empDetail)
      
      this.empDetail.forEach(element => {
       if(element.dateOfBirth!=null && element.dateOfBirth >= new Date().toISOString() && element.dateOfBirth.split("T")[0].split("-")[1] ==new Date().getMonth() + 1){
         this.Birthdaylist.push(element);
       }
       
       if(element.joiningDate !=null && element.joiningDate >= new Date().toISOString()&& element.joiningDate.split("T")[0].split("-")[1] ==new Date().getMonth() + 1){
        this.Anniversarylist.push(element);
       }
     });
    });
 
    if(this.userService.allUserData.length==0){
      this.userService.getAllUser()
    }
  }
  constructor(private breakpointObserver: BreakpointObserver,
    public userService: UserService) {}
}
