import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable({
  providedIn: 'root'
})
export class SidenavService {

   // With this subject you can save the sidenav state and consumed later into other pages.
   public sideNavState$: Subject<boolean> = new Subject();
   public sidenav: MatSidenav;

   constructor() { }

   public setSidenav(sidenav:MatSidenav){
     this.sidenav = sidenav
   }

   public open(){
     return this.sidenav.open()
   }

   public close(){
     return this.sidenav.close()
   }

   public toggle(){
     this.sidenav.toggle()
   }

}
