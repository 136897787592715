<div class="container-fluid" id="content">
  <div class="row">
    <div class="col-md-12">

      <!-- menu list starts -->
      <div class="mor_vert_icon_div ">
        <mat-icon class="mor_vert_icon " [matMenuTriggerFor]="menu">more_vert </mat-icon>
        <span class="aligned-with-icon">More</span>
        <mat-menu #menu="matMenu">
          <button mat-menu-item *ngIf="display ==='list'" (click)="changeDisplay('grid')">
            <mat-icon class="mat_icon">grid_view</mat-icon><span>Grid</span>
          </button>
          <button mat-menu-item *ngIf="display ==='grid'" (click)="changeDisplay('list')">
            <mat-icon class="mat_icon">menu </mat-icon><span>List</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item>
            <mat-icon class="mat_icon">sort_by_alpha</mat-icon><span>Sort</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item *ngIf="matTabsClickedIndex === 1 ">
            <mat-icon class="mat_icon">file_copy</mat-icon><span>Copy Clip</span>
          </button>
          <mat-divider></mat-divider>
          <button mat-menu-item  (click)="createpdf()">
            <mat-icon class="mat_icon"> insert_drive_file</mat-icon><span>Export</span>
          </button>
        </mat-menu>
      </div>
      <!-- menu list ends -->

      <mat-tab-group class="mt-2 mr-5" (selectedTabChange)="onTabChanged($event);">

        <!-- employee List and grid starts -->
        <mat-tab label="Employees"  >
          <div class="mt-4">
            <app-employee-card> </app-employee-card>
          </div>
        </mat-tab>
        <!-- employee List and grid Ends -->

        <!-- Unmarked employee starts -->
        <mat-tab label="Unmarked Employees" *ngIf="hasRoleBased">
          <div class="mt-4" >
            <app-employee-unmarked></app-employee-unmarked>
          </div>
        </mat-tab>
        <!-- Unmarked employee Ends -->

        <!-- employee leave starts -->
        <mat-tab label="On Leave" *ngIf="hasRoleBased">
          <h3>No need to work on this right now</h3>
        </mat-tab>
        <!-- employee leave Ends -->

      </mat-tab-group>

    </div>
  <div>
</div>