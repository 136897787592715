import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimeService {

  constructor() { }

  lastUpdated(lastUpdated: Date) {
    let currentDateTime = new Date()
    let yearDiff = currentDateTime.getFullYear() - lastUpdated.getFullYear()
    
    if (yearDiff > 0) {
      return yearDiff == 1?yearDiff + " year ago":yearDiff + " years ago"
    }

    let monthDiff = currentDateTime.getMonth() - lastUpdated.getMonth()

    if(monthDiff > 0 && monthDiff<=12){
      return monthDiff == 1? monthDiff + " month ago":monthDiff + " months ago"
    }

    

    let currentDateTimeMin = currentDateTime.getDate()
    let dateTimeMin = lastUpdated.getDate()

    let daysDiff =  currentDateTime.getDate() - lastUpdated.getDate()

    if(daysDiff > 0 && daysDiff<=31){
        return daysDiff==1?  daysDiff + " day ago" : daysDiff + " days ago" 
    }



    let millisecondDiff = currentDateTime.getTime() - lastUpdated.getTime()
    
    let hourDiff = Math.trunc(millisecondDiff/(1000*60*60)) 

    if(hourDiff >=1 && hourDiff <24 ){
      return hourDiff == 1 ? hourDiff + " hour ago" : hourDiff + " hours ago"
    }
    
    let minDiff = Math.trunc( millisecondDiff/(1000*60))

    if( minDiff >=1 && minDiff < 60 ){
      return minDiff == 1? minDiff + " minute ago":minDiff+" minutes ago"
    }

    let secDiff = Math.trunc(millisecondDiff/1000)
    if(secDiff >=0 && secDiff < 60){
      return secDiff == 1 ? secDiff+ " second ago":secDiff+" seconds ago"
    }

    return undefined

}
}
