<div class="row">
    <div class="col-md-12" *ngFor="let data of userDto">
        <mat-card  class="main-card mt-1" *ngIf="display === 'list'">
            <img class="logo-img" src="assets/female.png" alt="">
            <div class="col-md-1">
                <label class="firstAndLastname ml-0"> {{data.firstName + " " + data.lastName}}</label>
            </div>

            <div class="col-md-2 mt-2">
                <label class="user_name"> {{data.jobTitle}}</label>
                <label class="user_role">{{ data.department }}</label>
            </div>

            <div class="col-md-1 mt-4">
                <label class="user_id ml-4">FG:0104</label>
            </div>

            <div class="col-md-2 mt-4">
                <label class="user_email ">{{data.email}}</label>
            </div>

            <div class="col-md-2  mt-1">
                <label class="user_number ml-5 mt-4">{{data.mobileNumber}}</label>
            </div>

            <div class="col-md-2 mt-2">
                <label class="user_year "> 4 Years ago</label>
                <label class="user_date ">{{data.createdDate | date:
                    "dd/MMM/yyyy" }}</label>
            </div>
        </mat-card>
    </div>
</div>


<!-- employee card view starts -->

<div class="cards ">
    <div class=" ml-3 mt-3" *ngFor="let empDetail of userDto">
        <mat-card  class="grid-card" *ngIf="display === 'grid'">
            <div>
                <img class="mat_card_logo_img" src="assets/female.png" alt="">
            </div>
            <div>
                <label class="grid_name">{{empDetail.firstName + " " + empDetail.lastName}} </label>
            </div>
            <div>
                <label class="grid_position">FG:0104 | {{empDetail.jobTitle}} </label>
            </div>
            <div>
                <!-- <label class="grid_role"> {{empDetail.department}}</label> -->
                <label class="grid_role">Api Management and Integration</label>

            </div>
            <hr>
            <div>
                <label class="grid_date">{{empDetail.createdDate | date:
                    "dd/MMM/yyyy" }} | 4 Years ago</label>
            </div>
            <hr>
            <div>
                <label class="grid_number">
                    <mat-icon class="phone_icon mt-2  mr-2">phone</mat-icon> <span
                        class="mt-3 mr-5">{{empDetail.mobileNumber}}</span>
                </label>
            </div>
            <hr>
            <div class="mr-4">
                <label class="grid_email">
                    <mat-icon class="email_icon mr-2">email</mat-icon>
                    <span class="mt-2">{{empDetail.email}}</span>
                </label>
            </div>
            
        </mat-card>
    </div>
</div>
