<div style="position: relative;">
    <button mat-icon-button (click)="closeDialog()"
        style="color:white; position: absolute;z-index: 1;  top: 15px; right: 10px;">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="container-fluid ">
    <div class="row" style="height: 60px; color: white;background-color: #3D4A74;">
        <div class="headingTitle">
            <h2 *ngIf="this.data.activityType == 'Add'">Add Sub Goal</h2>
            <h2 *ngIf="this.data.activityType == 'Edit'">Edit Sub Goal</h2>
        </div>
    </div>

    <!-- Form for adding new sub goal -->
    <form *ngIf="this.data.activityType == 'Add'" [formGroup]="subGoalForm" (ngSubmit)="onSubmit()">
        <div class="row shadow-sm" style="height: 70px;">

            <div class="col-1 imgCol" style="text-align: center;"><span class="mx-auto"><img class="img-style"
                        [src]="this.initialAvatarService.getAvatar()"></span>
            </div>
            <div class="col-5 ml-0 pl-0 userName">

                <p class="h3">{{this.data.goalTypeLabel}}</p>
            </div>
            <div class="col-4">
            </div>
            <div class="col-2 submitBtnCol">
                <button mat-raised-button class="bg-success btn-success px-auto"><span
                        style="color: white;">Submit</span></button>
            </div>
        </div>

        <div class="row formContentCol">

            <div class="col">
                <div class="row rowHeightFirst">
                    <div class="col-12">
                        <p class='h5 m-0'>Sub Goal</p>
                        <mat-form-field appearance="standard">
                            <input class="w-100" matInput formControlName="subGoal" autocomplete="off">
                        </mat-form-field>
                    </div>

                </div>
                <div class="row">
                    <div class="col-12">
                        <p class='h5'>Description</p>
                    </div>
                    <div class="col-12">

                        <mat-form-field style="width: 600px;" appearance="fill">
                            <mat-label>Write description</mat-label>
                            <textarea matInput formControlName="description" cdkTextareaAutosize
                                #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="7"
                                cdkAutosizeMaxRows="15"></textarea>
                        </mat-form-field>
                    </div>
                </div>


            </div>
            <div class="col">
                <!-- Error Change this to real year value -->
                <div class="row rowHeightFirst">
                    <div class="col-12">
                        <div>
                            <p class='h5'>TimeFrame</p>
                        </div>
                        <div class="mt-3">
                            <p class="text-muted"> Jan 2021 - Dec 2021</p>
                        </div>

                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <p class='h5'>Value Range</p>
                    </div>
                    <div class="col-4">
                        <mat-form-field appearance="standard">
                            <mat-label>Initial Value</mat-label>
                            <input class="w-100" matInput formControlName="initialValue" autocomplete="off">
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field appearance="standard">
                            <mat-label>Target Value</mat-label>
                            <input class="w-100" matInput formControlName="targetValue" autocomplete="off">
                        </mat-form-field>
                    </div>
                    <div class="col-1">
                        <div style="padding-top: 25px;">
                            <span style="font-size: x-large;">%</span>
                        </div>
                    </div>
                    <div class="col-3"></div>
                </div>


            </div>


        </div>
    </form>

    <!-- Form for editing existing subgoal -->
    <form *ngIf="this.data.activityType == 'Edit'" [formGroup]="subGoalFormEdit" (ngSubmit)="onEdit(this.data.goal)">
        <div class="row shadow-sm" style="height: 70px;">

            <div class="col-1 imgCol" style="text-align: center;"><span class="mx-auto"><img class="img-style"
                        [src]="this.initialAvatarService.getAvatar()"></span>
            </div>
            <div class="col-5 ml-0 pl-0 userName">

                <p class="h3" *ngIf="this.data.activityType == 'Add'">{{this.data.goalTypeLabel}}</p>
                <p class="h3" *ngIf="this.data.activityType == 'Edit'">{{this.data.goal.title}}</p>
            </div>
            <div class="col-4">
            </div>
            <div class="col-2 submitBtnCol">
                <button mat-raised-button class="bg-success btn-success px-auto"><span
                        style="color: white;">Submit</span></button>
            </div>
        </div>

        <div class="row formContentCol">

            <div class="col">
                <div class="row mb-5 rowHeightFirst">
                    <div class="col-12">

                        <div>
                            <p class='h5'>Current progress</p>
                        </div>
                        <div class="mt-2" style="width: 50%;">
                            <app-progress-bar [metric]="this.data.goal.metric"
                                [progressValue]="this.data.goal.currentValue" [goalStatus]="this.data.goal.goalStatus">
                            </app-progress-bar>
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-4">
                        <div>
                            <p class='h5'>Update Progress</p>
                        </div>
                        <div>
                            <mat-form-field appearance="standard">
                                <input class="w-100" matInput formControlName="progress" autocomplete="off">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-4">
                        <div>
                            <p class='h5'>Goal Status</p>
                        </div>
                        <div>
                            <mat-form-field appearance="standard">
                                <mat-label>Choose Goal Status</mat-label>
                                <mat-select formControlName="goalStatus">
                                    <mat-option *ngFor="let status of this.goalStatusLabelRef | keyvalue"
                                        [value]="goalStatusRef[status.key]">
                                        <span class="circle" [className]="this.goalStatusColor[status.value]"></span><span class="trackStatusText text-muted">{{status.value}}</span>
                                        </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-4"></div>
                </div>


            </div>

            <div class="col">
                <!-- Error Change this to real year value -->
                <div class="row mb-5 rowHeightFirst">
                    <div class="col-12">
                        <div>
                            <p class='h5'>TimeFrame</p>
                        </div>
                        <div class="mt-3">
                            <p class="text-muted"> Jan {{this.year}} - Dec {{this.year}}</p>
                        </div>

                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <p class='h5'>Value Range</p>
                    </div>
                    <div class="col-4">
                        <mat-form-field appearance="standard">
                            <mat-label>Initial Value</mat-label>
                            <input class="w-100" matInput formControlName="initialValue" autocomplete="off">
                        </mat-form-field>
                    </div>
                    <div class="col-4">
                        <mat-form-field appearance="standard">
                            <mat-label>Target Value</mat-label>
                            <input class="w-100" matInput formControlName="targetValue" autocomplete="off">
                        </mat-form-field>
                    </div>
                    <div class="col-1">
                        <div style="padding-top: 25px;">
                            <span style="font-size: x-large;">%</span>
                        </div>
                    </div>
                    <div class="col-3"></div>
                </div>


            </div>


        </div>
    </form>

</div>