<mat-card class="main-card">
    <div class="col-md-12">
      <ul class="nav nav-pills nav-fill nav-justified" id="pills-tab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab"
            aria-controls="pills-home" aria-selected="true">Birthdays</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab"
            aria-controls="pills-profile" aria-selected="false">Anniversary</a>
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent p-3">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel"
          aria-labelledby="pills-home-tab">
          <table class="table table-borderless">
            <tbody>
              <tr *ngFor="let user of Birthdaylist; let i = index">
                <td><img class="img" matListAvatar src="assets/female.png" alt="...">
                  &nbsp; {{user.firstName}}&nbsp;{{user.lastName}}</td>
                <td>{{user.dateOfBirth.split("T")[0]}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
          <table class="table table-borderless">
            <tbody>
              <tr *ngFor="let user of Anniversarylist; let i = index">
                <td><img class="img" matListAvatar src="assets/female.png" alt="...">
                  &nbsp; {{user.firstName}}&nbsp;{{user.lastName}}</td>
                <td>{{user.joiningDate.split("T")[0]}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </mat-card>
