<mat-card class="main-card pr-0 pl-0">
    <table class="table table-striped table-borderless">
      <thead>
        <tr>
          <th class="head">Upcoming Holidays</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>03 May Tuesday</td>
          <td>Eid</td>
          <td></td>
        </tr>
        <tr>
          <td>16 May Monday</td>
          <td>Buddha Purnima</td>
          <td>
            <a class="text-align-right" href="/home">Apply</a>
          </td>
        </tr>
        <tr>
          <td>16 August Monday</td>
          <td>Raksha Bandhan</td>
          <td>
            <a class="text-align-right" href="/home">Apply</a>
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card>
