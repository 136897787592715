<p>create-forms-dialog works!</p>
<div class="form-group">
    
    <mat-action-row>
        <button class="btn btn-primary" style="margin: 5px;" (click)="onAddFormTemplate()">Add
            Forms <i class="pl-2 fas fa-plus"></i></button>

    </mat-action-row>
    <mat-action-row *ngIf="formCardFlag">
       
        <app-feedback-form-card class="col-3"  *ngFor="let feedback of this.formTemplatesModel"
            [feedback]="feedback"></app-feedback-form-card>
      
    </mat-action-row>

    
</div>
<div>
    <button type="button" class="btn btn-primary" style="float: right; margin-right: 10px;margin-bottom: 20px;"
      (click)="onSave()">Save</button>
    <button type="button" class="btn btn-danger" style="float: right; margin-right: 10px;margin-bottom: 20px;"
      (click)="onClose()">Close</button>
</div>