import { ProjectDTO } from '../dtos/ProjectDTO';
import { ClientDTO } from '../dtos/ClientDTO';
import { FormSchemaDTO } from '../dtos/FormSchemaDTO';

interface IForm {
    id?: string;
    formTemplate?:string;
    formType?:string;
    careerManagerTeamId?:string;
    projectId?:string;
}

export class Form {
    id: string;
    formTemplate:string;
    formType:string;
    careerManagerTeamId:string;
    projectId:string;
    constructor(form: IForm) {
        if (form && form.id) {
            this.id = form.id;
        }
        this.formTemplate = form && form.formTemplate || null;
        this.formType = form && form.formType || null;
        this.projectId = form && form.projectId || null;
        this.careerManagerTeamId = form && form.careerManagerTeamId || null;
 
    }
}