<mat-card class="main-card pr-0 pl-0">
    <table class="table table-striped table-borderless">
      <thead>
        <tr>
          <th class="head">My Projects</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Pulse Hrms Solution</td>
          <td>Pending</td>
          <td>
            <div class="progress">
              <div class="progress-bar bg-success" role="progressbar" aria-valuenow="80"
                aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </td>
        </tr>
        <tr>
          <td>XYZ</td>
          <td>Pending</td>
          <td>
            <div class="progress">
              <div class="progress-bar1 bg-success" role="progressbar" aria-valuenow="10"
                aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </td>
        </tr>
        <tr>
          <td>Abc</td>
          <td>Pending</td>
          <td>
            <div class="progress">
              <div class="progress-bar2 bg-success" role="progressbar" aria-valuenow="5"
                aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </mat-card>
