<div class="container-fluid mt-5">
    <div class="row">
        <div class="col-md-6">
            <h1 class="mt-3">Employees</h1>
        </div>
        <div class="col-md-6 ">
            <div class="float-right mt-2 ">
                <div class="gridlist">
                    <div class="item ">
                        <mat-icon>sort_by_alpha</mat-icon>
                        <span class="caption">Sort</span>
                    </div>
                    <div class="item grid-icon" *ngIf="display ==='grid'">
                        <mat-icon class="gridAndList" (click)="changeDisplay('list')">list</mat-icon>
                        <span class="caption">List</span>
                    </div>
                    <div class="item grid-icon" *ngIf="display ==='list'">
                        <mat-icon class="gridAndList" (click)="changeDisplay('grid')">grid_view </mat-icon>
                        <span class="caption">Grid</span>
                    </div>
                    <div class="item ">
                        <mat-icon (click)="addEmployeeDialog()" aria-hidden="false" aria-label="Example home icon">
                            person_add
                        </mat-icon>
                        <span class="caption">Add</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="row mt-5">
        <div class="col-md-12">
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, fugiat obcaecati voluptatibus maiores nisi
                doloribus ex perspiciatis? Magnam asperiores assumenda odio tenetur nam at nobis debitis nihil! Aliquid,
                nam rerum.</p>
            <mat-card class="main-card">
                <img class="logo-img" src="assets/female.png" alt="">
                <div class="col-md-1">
                    <label class="firstAndLastname">Diya Kumar</label>
                </div>

                <div class="col-md-2 mt-2">
                    <label class="user_name"> SR_MANAGER</label>
                    <label class="user_role">API_MANAGEMENT_AND_INTEGRATION</label>
                </div>

                <div class="col-md-1 mt-4">
                    <label class="user_id ml-4">FG:0104</label>
                </div>

                <div class="col-md-2 mt-4">
                    <label class="user_email ">diya.kumar@freshgravity.com</label>
                </div>

                <div class="ml-4 mt-4 ">
                    <label class="user_number ml-5">+91 990252202</label>
                </div>

                <div class="col-md-2 mt-2">
                    <label class="user_year ml-5"> 4 Years ago</label>
                    <label class="user_date ml-5">4/12/2018</label>
                </div>

                <div class="col-md-1 mt-2 ">
                    <button class="user-btn ml-4">
                        <span >
                            Connect
                        </span>
                    </button>
                </div>
            </mat-card>
            <mat-card class="main-card mt-2">
                <img class="logo-img" src="assets/female.png" alt="">
                <div class="col-md-1">
                    <label class="firstAndLastname">Diya Kumar</label>
                </div>

                <div class="col-md-2 mt-2">
                    <label class="user_name"> SR_MANAGER</label>
                    <label class="user_role">API_MANAGEMENT_AND_INTEGRATION</label>
                </div>

                <div class=" col-md-1 mt-4">
                    <label class="user_id ml-4">FG:0104</label>
                </div>

                <div class="col-md-2 mt-4">
                    <label class="user_email ">diya.kumar@freshgravity.com</label>
                </div>

                <div class="ml-4 mt-4 ">
                    <label class="user_number ml-5">+91 990252202</label>
                </div>

                <div class="col-md-2 mt-2">
                    <label class="user_year ml-5"> 4 Years ago</label>
                    <label class="user_date ml-5">4/12/2018</label>
                </div>

                <div class="col-md-1 mt-2 ">
                    <button class="user-btn ml-4">
                        <span >
                            Connect
                        </span>
                    </button>
                </div>
            </mat-card>
            <mat-card class="main-card mt-2">
                <img class="logo-img" src="assets/female.png" alt="">
                <div class="col-md-1">
                    <label class="firstAndLastname">Diya Kumar</label>
                </div>

                <div class="col-md-2 mt-2">
                    <label class="user_name"> SR_MANAGER</label>
                    <label class="user_role">API_MANAGEMENT_AND_INTEGRATION</label>
                </div>

                <div class=" col-md-1 mt-4">
                    <label class="user_id ml-4">FG:0104</label>
                </div>

                <div class="col-md-2 mt-4">
                    <label class="user_email ">diya.kumar@freshgravity.com</label>
                </div>

                <div class="ml-4 mt-4 ">
                    <label class="user_number ml-5">+91 990252202</label>
                </div>

                <div class="col-md-2 mt-2">
                    <label class="user_year ml-5"> 4 Years ago</label>
                    <label class="user_date ml-5">4/12/2018</label>
                </div>

                <div class="col-md-1 mt-2 ">
                    <button class="user-btn ml-4">
                        <span >
                            Connect
                        </span>
                    </button>
                </div>
            </mat-card>
            <mat-card class="main-card mt-2">
                <img class="logo-img" src="assets/female.png" alt="">
                <div class="col-md-1">
                    <label class="firstAndLastname">Diya Kumar</label>
                </div>

                <div class="col-md-2 mt-2">
                    <label class="user_name"> SR_MANAGER</label>
                    <label class="user_role">API_MANAGEMENT_AND_INTEGRATION</label>
                </div>

                <div class=" col-md-1 mt-4">
                    <label class="user_id ml-4">FG:0104</label>
                </div>

                <div class="col-md-2 mt-4">
                    <label class="user_email ">diya.kumar@freshgravity.com</label>
                </div>

                <div class="ml-4 mt-4 ">
                    <label class="user_number ml-5">+91 990252202</label>
                </div>

                <div class="col-md-2 mt-2">
                    <label class="user_year ml-5"> 4 Years ago</label>
                    <label class="user_date ml-5">4/12/2018</label>
                </div>

                <div class="col-md-1 mt-2 ">
                    <button class="user-btn ml-4">
                        <span >
                            Connect
                        </span>
                    </button>
                </div>
            </mat-card> --> 

            <div class="mt-2">
                <app-employee-card *ngFor="let emp of this.employeeServie.usersDto ;let i = index" [index]=i [empDetail]=emp
                    [initialAvatar]="this.initialAvatarService.getInitialImage(this.empDetail.firstName,this.empDetail.lastName)">
                </app-employee-card>
                

            </div>
           
        <!-- </div>
    </div>
</div> -->