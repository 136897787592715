import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { UserService } from 'src/app/services/user/user.service';
import { now } from 'moment-timezone';
import { UserDTO } from 'src/app/dtos/UserDTO';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent {
  /** Based on the screen size, switch from standard to one column per row */
  ngOnInit():void{

  }
  constructor(){}
}
