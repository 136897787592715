<div class="container content">
    <div class="row topRow"></div>
    <div class="row mainContent">

      <div class="col-1 mainContentColSide"></div>
      <div class="col-10 mainContentColCenter">
            <app-main-content-feedback></app-main-content-feedback>
      </div>
      <div class="col-1 mainContentColSide"></div>

    </div>
    <div class="row topRow"></div>
</div>