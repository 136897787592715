import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { parse } from 'path';
import { ClientService } from 'src/app/services/client/client.service';
import { ImageService } from 'src/app/services/Images/image.service';
import { ProjectService } from 'src/app/services/project.service';
import { UserService } from 'src/app/services/user/user.service';
@Component({
  selector: 'app-project-detail-view',
  templateUrl: './project-detail-view.component.html',
  styleUrls: ['./project-detail-view.component.scss']
})
export class ProjectDetailViewComponent implements OnInit {

  constructor(private route:ActivatedRoute,
              private projectService:ProjectService,
              private userService:UserService,
              private imageService:ImageService,
              private clientService:ClientService,
              private router:Router) { }
  
  public projectId;
  public projectData;
  public clientData;
  public managerData;
  public supervisorData;
  public peerData;
  public projectLogo;

  ngOnInit(): void {
    this.projectId = this.route.snapshot.paramMap.get('id');
    console.log(this.projectId)

    this.projectService.getProjectById(this.projectId).subscribe(res=>{
        console.log(res.body)
        this.projectData = res.body;
        this.supervisorData = res.body.supervisors
        this.peerData = res.body.users

        this.userService.getUserByID(res.body.managerId).subscribe(resUser=>{
          this.managerData = resUser.body
          console.log(resUser.body)
        })

        this.clientService.getClientByIdSubscribe(res.body.clientId).subscribe(resClient=>{
          this.clientData = resClient.body
          this.setProjectLogo(resClient.body.imageId)
          console.log(resClient.body)
        })
    })
  }

  setProjectLogo(imageId){
    this.projectLogo = this.imageService.getImage(imageId)
  }

  backToProject(){
    this.router.navigate(['project']);
  }

}
