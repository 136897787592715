
<div class="container-fluid mt-4">

    <div class="row">
        <div class="col-3" *ngFor="let project of this.projectService.projects">

            <div class="card cardContent">
                <button style="padding:0px" class="editBtn" mat-flat-button (click)="onEdit(project)">
                    <mat-icon style="color: #3c3c3c;">edit</mat-icon>
                </button>
                <button style="padding:0px" class="deleteBtn" mat-icon-button (click)="deleteProject(project.id)" >
                    <mat-icon class="iconDelete">delete_outline</mat-icon>
                </button>
                <button mat-flat-button>
                    <div class="card-content">
                        <div class="card-body">
                            <img class="img-style" src="assets/download.png" alt="Card image cap">
                            <p class="h5 card-title">{{project.projectName}}</p>
                            <p class="h6 card-text"><small class="text-muted">{{project.projectDescription}}</small></p>
                        </div>
                    </div>
                </button>
            </div>

        </div>
    </div>