<mat-sidenav-container>
    <mat-sidenav  #sidenav id="sidenav" [class.menu-open]="isMenuOpen" [class.menu-close]="!isMenuOpen"
        opened>
        <app-sidebar-menu></app-sidebar-menu>
    </mat-sidenav>

    <mat-sidenav-content [ngStyle]="{ 'margin-left.px': contentMargin }">
        <mat-toolbar class="position-fixed">
            <app-header></app-header>
        </mat-toolbar>

        <div class="content">
            <mat-icon *ngIf="this.isExpanded" [ngStyle]="{ 'margin-left.px': menuMargin }" mat-list-icon class="arrow"
                (click)="onToolbarMenuToggle()">
                <span [@rotatedState]='state' class=" menu-button material-icons">keyboard_double_arrow_right</span>
            </mat-icon>
            <app-header class="sticky" ></app-header>
            <div class="main"> 
                <div class="mt-3"></div>
                <router-outlet></router-outlet>
            </div>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>