<div class="container-fluid cardContent">
    <div class="row">
        <div class="col-1 col-sm-1 justify-content-center"><img class="img-style" src="assets/download.png"></div>
        <div class="col-3">
            <div class="alignContent"></div>
            <p class="h3">Tejas Kuthe</p>
            <p class="h5"><small class="text-muted">Consultant</small></p>
        </div>

        <div class="col-6 col-sm-6 text alignContent justify-content-md-center">
            <p>Status : <span style="color: brown;">PENDING </span></p>
            <p>Feedback For : Akeeb Mulani</p>
            <p>Project : Performance management system</p>
            
        </div>
        <div class="col-2 alignContent text-center">
            <button mat-flat-button><mat-icon class="alignExpand" aria-hidden="false" aria-label="Example home icon">email</mat-icon></button>
            <p class="alignExpandText">Resend Email</p>
        </div>
    </div>

</div>