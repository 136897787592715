import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-my-team',
  templateUrl: './my-team.component.html',
  styleUrls: ['./my-team.component.scss']
})
export class MyTeamComponent implements OnInit {
  empDetail=[];
  ngOnInit():void{
    this.userService.getUserSubscribe().subscribe(res => {
      this.userService.setUsersDto(res.body);
      this.empDetail = this.userService.usersDto;
    });
 
    if(this.userService.allUserData.length==0){
      this.userService.getAllUser()
    }
  }
  constructor(private breakpointObserver: BreakpointObserver,
    public userService: UserService) {}
}
