import { ReviewStatus } from "../services/employeeReview/employeeReview-enum";

export class OneOnOneMeetingDto {
    id: string;
    directReporteeId: string
    meetingDate: Date
    meetingSummary: string
    status: ReviewStatus
    careerManagerId:string;
    constructor(){
        this.directReporteeId = "";
        this.meetingSummary = "";
        this.meetingDate = new Date();
        this.status = ReviewStatus.COMPLETED;
        this.careerManagerId = "";
    }

}