import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AttendanceUrlService{

private readonly attendanceBaseApi = '/api/attendances/date/status';
private readonly attendanceStatus_param = 'attendanceStatus'
private readonly date_param = 'date'
    attendanceStatus: string;
constructor(){}

getAttendanceUrl(attendanceStatus,date)
//{http://localhost:8080/api/attendances/date/status?attendanceStatus=PRESENT&date=2022-06-14
 {   return this.attendanceBaseApi +'?'+this.attendanceStatus_param+'='+ attendanceStatus
        + '&' + this.date_param + '='+ date}
}