import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { OneOnOneMeetingComponent } from 'src/app/components/one-on-one-meeting/one-on-one-meeting/one-on-one-meeting.component';
import { Goal } from 'src/app/models/goal';
import { User } from 'src/app/models/user';
import { Activity } from 'src/app/services/activity/activity-enum';
import { AngularSpinnerService } from 'src/app/services/angular-spinner.service';
import { GoalService } from 'src/app/services/goal/goal.service';
import { GoalType } from 'src/app/services/goalSheet/goal-sheet-enum';
import { ImageService } from 'src/app/services/Images/image.service';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { threadId } from 'worker_threads';
import { AddGoalComponent } from './add-goal/add-goal.component';

@Component({
  selector: 'app-goal-sheet-accordian',
  templateUrl: './goal-sheet-accordian.component.html',
  styleUrls: ['./goal-sheet-accordian.component.scss']
})
export class GoalSheetAccordianComponent implements OnInit {

  @Input() goalType: GoalType;
  @Input() goalTypeLabel: string;
  @Input() userId;
  @Input() year;
  @Input() activityType;
  @Input() userName;
  @Input() initialAvatar;
  
  //Currently it is not of any type but it should be of type equal to the type of subGoalData[goalType]. 
  // For this purpose we will have to create a iterface and connect the interface to subGoalData as well as to this variable
  /** accordianData should be equal to followind object
   * {
      label: goalLabel,
      progress: 0,
      totalSubgoalProgress: 0,
      lastUpdated: "",
      subGoalList: []
    }
   */
  @Input() accordianData;


  public lastUpdated;
  public avataImageLink;
  panelOpenState = false;
  public accordianSubGoalList: Goal[]
  public name;
  constructor(public initialAvatarService: InitialAvatarService,
    private dialog: MatDialog,
    public userDetailService: UserDetailsService,
    public goalService: GoalService,
    public userService: UserService,
    public imageService:ImageService,
    private angularSpinnerService:AngularSpinnerService
  ) {
  }

  ngOnInit(): void {
    if (this.userId && this.year && this.goalType) {
      this.goalService.getGoalByGoalTypeUserIdAndYearSubscribe(this.goalType, this.userId, this.year).subscribe(res => {
        this.goalService.setGoals(res.body);
        this.userService.getUserByID(this.userId).subscribe(res=>{
          let user:User = new User(res.body)
          this.name = this.userService.processName(this.userDetailService.getUserDetails())
        })
        this.angularSpinnerService.stop()
        this.accordianSubGoalList = this.goalService.goalsList
        console.log(this.goalService.subGoalsData[GoalType.BuildingTheBusinessIP].progress)
        this.lastUpdated = this.goalService.subGoalsData[this.goalType].lastUpdated
      });
       
    }
  }


  addSubGoal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "70%";
    dialogConfig.height = "75%";
    dialogConfig.data = {
      goalType: this.goalType,
      goalTypeLabel: this.goalTypeLabel,
      activityType: Activity.ADD
    }
    // dialogConfig.data = employeeReview
    // dialogConfig.data = {
    //   employeeReview: employeeReview,
    //   disableIfCompleted: employeeReview.reviewStatus == ReviewStatus.COMPLETED?true:false
    // }
    // dialogConfig.data = this.project;
    const dialogRef = this.dialog.open(AddGoalComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

}
