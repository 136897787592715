import { User } from "./user";

interface IFeedback {
    id?: string;
    userFromDesignation?:string;
    userToDesignation?:string;
    createdDateTime?:string;
    updatedDateTime?:string;
    formTemplate?:string;
    formTemplateType?:string;
    formSubmissionAnswer?:string;
    feedbackSubmitStatus?:string;
    readStatus?:string;
    userReviewTo?:User;
    userReviewFrom?:User;
    createdBy?:User;
    updatedBy?:User;
    careerManager?:User;
    feedbackType?:string
}

export class Feedback {

    id: string;
    userFromDesignation:string;
    userToDesignation:string;
    createdDateTime:string;
    updatedDateTime:string;
    formTemplate:string;
    formTemplateType:string;
    formSubmissionAnswer:string;
    feedbackSubmitStatus:string;
    readStatus:string;
    userReviewTo:User;
    userReviewFrom:User;
    createdBy:User;
    updatedBy:User;
    careerManager:User;
    feedbackType:string;
    constructor(feedback: IFeedback) {
        if (feedback && feedback.id) {
            this.id = feedback.id;
        }
        this.userFromDesignation = feedback && feedback.userFromDesignation || null
        this.userToDesignation = feedback && feedback.userToDesignation || null
        this.createdDateTime = feedback && feedback.createdDateTime || null
        this.updatedDateTime = feedback && feedback.updatedDateTime || null
        this.formTemplate = feedback && feedback.formTemplate || null
        this.formTemplateType = feedback && feedback.formTemplateType || null
        this.formSubmissionAnswer = feedback && feedback.formSubmissionAnswer || null
        this.feedbackSubmitStatus = feedback && feedback.feedbackSubmitStatus || null
        this.readStatus = feedback && feedback.readStatus || null
        this.userReviewTo = feedback && feedback.userReviewTo || null
        this.userReviewFrom = feedback && feedback.userReviewFrom || null
        this.createdBy = feedback && feedback.createdBy || null
        this.updatedBy = feedback && feedback.updatedBy || null
        this.careerManager = feedback && feedback.careerManager || null
        this.feedbackType = feedback && feedback.feedbackType || null
    }
}