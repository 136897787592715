import { environment } from "./environments/environment";

export const OAuthSettings = {
    appId: 'b2cf1e82-2a76-4a0e-b604-38c3fd8f03ca',
    redirectUri: environment.BASE_API_URL_FRONTEND,
    postLogoutRedirectUri: environment.BASE_API_URL_FRONTEND,
    scopes: [
      "user.read",
      "mailboxsettings.read",
      "calendars.readwrite"
    ]
  };
  