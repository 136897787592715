import { Component, OnInit } from '@angular/core';
import { AngularSpinnerService } from 'src/app/services/angular-spinner.service';

@Component({
  selector: 'app-angular-spinner',
  templateUrl: './angular-spinner.component.html',
  styleUrls: ['./angular-spinner.component.scss']
})
export class AngularSpinnerComponent implements OnInit {

  constructor(public angularSpinnerService:AngularSpinnerService) { }

  ngOnInit(): void {
    
  }

}
