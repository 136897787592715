<div style="position: relative;">
    <button mat-icon-button (click)="closeDialog()" style="position: absolute;z-index: 1;  top: 15px; right: 15px;">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="container-fluid ">
    <div class="row" style="height: 60px; color: white;background-color: #3D4A74;">
        <div class="headingTitle">
            <h2>View Employee Goal Sheet</h2>
        </div>
    </div>
    
    
        <app-goal-sheet [userId]="this.data.employee.employee.id" [year]="this.data.year" [activityType]="this.data.activityType" [userName]="this.name" [initialAvatar]="this.initialAvatar">
        </app-goal-sheet>
    
</div>