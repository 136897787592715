import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CreateFormsDialogComponent } from '../create-forms-dialog/create-forms-dialog.component';
import { FeedbackGenerationDialogComponent } from '../feedback-generation-dialog/feedback-generation-dialog.component';

@Component({
  selector: 'app-project-accordian',
  templateUrl: './project-accordian.component.html',
  styleUrls: ['./project-accordian.component.scss']
})
export class ProjectAccordianComponent implements OnInit {

  @Input() project;

  panelOpenState : Boolean = false;
  sendValue: any;
  
  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.panelOpenState = false;
  }

  openCreateDialog(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "95%";
    dialogConfig.height = "90%";
    dialogConfig.data = this.project;
    data: { pageValue: this.sendValue }
    const dialogRef = this.dialog.open(CreateFormsDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });}

  openGenerateDialog(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "95%";
    dialogConfig.height = "90%";
    dialogConfig.data = this.project;
    data: { pageValue: this.sendValue }
    const dialogRef = this.dialog.open(FeedbackGenerationDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }
  
}
