import { Injectable } from '@angular/core';
import { AverageScoreDTO } from 'src/app/dtos/AverageScoreDTO';
import { Feedback } from 'src/app/models/feedback';
import { User } from 'src/app/models/user';
import { FeedbackUrlService } from '../restAPI/feedbackURLs/feedback-url.service';
import { RestAPIService } from '../restAPI/rest-api.service';
import { SpinnerService } from '../spinner.service';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {


  public feedbacks: Feedback[] = [];
  feedbackPendingList = []
  feedbackSubmittedList = []
  public averageScore: AverageScoreDTO

  constructor(private restApi: RestAPIService,
    private spinnerService:SpinnerService,
    private feedbackUrl: FeedbackUrlService) { }

  getAllFeedbackSubscribe() {
    return this.restApi.get(
      this.feedbackUrl.getBaseUrl()
    )
  }

  feedbackEmailReminderSetting(userReviewTo, userReviewFrom) {
    return this.restApi.get(
      this.feedbackUrl.getEmailReminderUrl(userReviewTo, userReviewFrom )
    )
  }

  getFeedbackByCareerManagerIdSubscribe(careerManagerId) {
    return this.restApi.get(
      this.feedbackUrl.getCareerManagerUrl(careerManagerId)
    )
  }

  getFeedbackByUserIdAndStatus(userFrom, readStatus, feedbackSubmitStatus) {
    return this.restApi.get(
      this.feedbackUrl.getFeedBackByUserIdAndStatusUrl(userFrom, readStatus, feedbackSubmitStatus)
    )
  }

  setFeedback(feedbacks) {
    this.feedbacks = [];
    for (let feedback of feedbacks) {
      this.feedbacks.push(new Feedback(feedback))
    }
  }

  setFeedbackPendingList(feedbacks) {
    this.feedbackPendingList = [];
    for (let feedback of feedbacks) {
      this.feedbackPendingList.push(new Feedback(feedback))
    }
  }


  setFeedbackSubmittedList(feedbacks) {
    this.feedbackSubmittedList = [];
    for (let feedback of feedbacks) {
      this.feedbackSubmittedList.push(new Feedback(feedback))
    }
  }

  addFeedbackSubscribe(payload) {
    return this.restApi.post(
      this.feedbackUrl.getBaseUrl(),
      payload
    )
  }

  updateFeedbackSubscribe(payload) {
    return this.restApi.put(
      this.feedbackUrl.getBaseUrl(),
      payload
    )
  }

  getAverageScore(userReviewTo, year) {
    return this.restApi.get(
      this.feedbackUrl.getAverageScoreUrl(userReviewTo, year)
    )
  }

  setAverageScore(averageScore) {
    this.averageScore = averageScore
  }

}
