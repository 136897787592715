<div class="container content" >
    <div class="row topRow"></div>
    <div class="row mainContent" >

      <div class="col-1 mainContentColSide"></div>
      <div class="col-10 mainContentColCenter">
          
        
        
        <div class="row projectHeader">
                <button mat-icon-button class="backBtn" (click)="backToProject()">                    
                    <mat-icon style="color: #3c3c3c;">west</mat-icon>
                </button>
                <div class="col-2" >
                    <img class="img-style-header" [src]="this.projectLogo" alt="Card image cap">
                </div>
                <div class="col-4 projectNameHeader">
                    <p class="h3">{{this.projectData.projectName}}</p>
                    <p class="h5"><small class="text-muted">{{this.projectData.projectDescription}}</small></p>
                </div>
                <div class="col-6 projectCodeHeader">
                    <p class="h3"><small class="text-muted">{{this.projectData.projectCode}}</small></p>
                </div>

          </div>
          





          <div class="row" >

            <div class="container-fluid mt-4" >
                <div class="row employeeHeading"> <p class="h3">  Manager</p></div>
                <div class="row">
                    <div class="col-3">
            
                        <div class="card cardContent" style="width: 11rem;">
                            <button mat-flat-button>
                                <div class="card-content">
                                    <div class="card-body">
                                        <img class="img-style" src="assets/download.png" alt="Card image cap">
                                        <p class="h5 card-title">{{this.managerData.firstName+ ' ' + this.managerData.lastName}}</p>
                                        <p class="h6 card-text"><small class="text-muted">{{this.managerData.jobTitle}}</small></p>
                                    </div>
                                </div>
                            </button>
                        </div>
            
                    </div>
                </div>

          </div>

          <div class="container-fluid mt-4" >
            <div class="row employeeHeading"> <p class="h3">Supervisor</p></div>
            <div class="row">
                <div class="col-3" *ngFor="let user of this.supervisorData">
        
                    <div class="card cardContent" style="width: 11rem;">
                        <button mat-flat-button>
                            <div class="card-content">
                                <div class="card-body">
                                    <img class="img-style" src="assets/download.png" alt="Card image cap">
                                    <p class="h5 card-title" style="text-align: center;">{{user.firstName+ ' ' + user.lastName}}</p>
                                    <p class="h6 card-text"><small class="text-muted">{{user.jobTitle}}</small></p>
                                </div>
                            </div>
                        </button>
                    </div>
        
                </div>
            </div>

      </div>
          </div>
         
         
          
      </div>
      <div class="col-1 mainContentColSide"></div>

    </div>
    <div class="row topRow"></div>
</div>