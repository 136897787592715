import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize, retry} from 'rxjs/operators';
import { LoadingService } from './loading.service';
import {StorageService} from './storage.service'
import { tap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private storageService: StorageService){}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Add Auth Token
    // In production you would get the token value from an auth service
    const token = this.storageService.getItem(StorageService.JWT_TOKEN);
    req = req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
   return next.handle(req);
    
  }
}