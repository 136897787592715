<div class="container-fluid mt-5">
  <div class="row mt-5">
    <div class="col-md-12">
      <app-notificationsCarousel></app-notificationsCarousel>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-md-3">
      <app-leave-summary></app-leave-summary>
    </div>
    <div class="col-md-5">
      <app-upcoming-holidays></app-upcoming-holidays>
    </div>
    <div class="col-md-4">
      <app-upcoming-events></app-upcoming-events>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-md-7">
      <app-my-projects></app-my-projects>
    </div>
    <div class="col-md-5 mb-4">
      <app-my-team></app-my-team>
    </div>
  </div>
</div>