import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MainContentComponent } from 'src/app/components/employee/main-content/main-content.component';
import { GoalService } from 'src/app/services/goal/goal.service';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-goal-sheet-view-employee',
  templateUrl: './goal-sheet-view-employee.component.html',
  styleUrls: ['./goal-sheet-view-employee.component.scss']
})
export class GoalSheetViewEmployeeComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private goalService:GoalService,
              private userService:UserService,
              public initialAvatarService:InitialAvatarService, 
              public dialogRef: MatDialogRef<MainContentComponent>) { }
public name;
public initialAvatar;
  ngOnInit(): void {
    this.goalService.resetGoalService();

    if(this.data.employee){
      this.name = this.userService.processName(this.data.employee.employee)}
      this.initialAvatar = this.initialAvatarService.getInitialImage(this.data.employee.employee.firstName,this.data.employee.employee.lastName)
  }

  closeDialog() {
    this.dialogRef.close()
  }

}
