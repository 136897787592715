import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { User } from 'src/app/models/user';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { UitoggleService } from 'src/app/services/uitoggle.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { UserService } from 'src/app/services/user/user.service';
import { AddEmployeeCardComponent } from '../add-employee-card/add-employee-card.component';
import { DeleteEmployeeComponent } from '../delete-employee/delete-employee.component';

@Component({
  selector: 'app-employee-card',
  templateUrl: './employee-card.component.html',
  styleUrls: ['./employee-card.component.scss']
})
export class EmployeeCardComponent implements OnInit {

  @Input() empDetail;
  @Input() initialAvatar;
  @Input() public index;
  @Input() display;

  userDto: User[] = [];
  hasRoleBased: any;
  connectingBtn: Boolean 
  department;
  setngclass

  constructor(public userService: UserService,
    public userDetailService: UserDetailsService,
    private dialog: MatDialog,
    public initialAvatarService: InitialAvatarService,
    private uitoggle: UitoggleService) { }


  ngOnInit(): void {
    this.hasRoleBased = this.userDetailService.hasRole(['ROLE_ADMIN'])
    this.uitoggle.cast.subscribe(data => this.display = data);
    this.userService.getUserSubscribe().subscribe((response) => {
      (response.body).forEach((data) => {
        this.userDto.push(new User(data));
      })
    })
  }


  
  editEmployeeDialog(empData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "100%";
    dialogConfig.height = "98%";
    dialogConfig.data = empData
    const dialogRef = this.dialog.open(AddEmployeeCardComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  addEmployeeDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "100%";
    dialogConfig.height = "98%";
    // dialogConfig.data = this.project;
    const dialogRef = this.dialog.open(AddEmployeeCardComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  deleteEmployee(empData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "600px";
    dialogConfig.height = "300px";
    dialogConfig.data = empData;
    const dialogRef = this.dialog.open(DeleteEmployeeComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      debugger
      this.userService.getUserSubscribe().subscribe((response) => {
        debugger
        this.userDto = [];
        (response.body).forEach((data) => {
          this.userDto.push(data);
          console.log(this.userDto)
        })
      })
    });
  }

  slackConnect(data) {
    alert("slack works");
    const id = data.id
  }

  teamsConnect(data) {
    alert("teams works");
    const id = data.id
  }




  deleteEmployeedata() {
    alert('hi')
  }

  editEmployee() {
    alert('hi');
  }
}
