import { FeedbackSubmitStatus, FeedbackReadStatus } from 'src/app/services/feedback/feedback-enum'
import { Feedback } from '../models/feedback';

export class FeedbackDTO {

    public id: string;
    public careerManagerId: string;
    public createdById: string
    public createdDateTime: Date;
    public feedbackSubmitStatus: string;
    public formSubmissionAnswer: string;
    public formTemplate: string;
    public formTemplateType: string;

    public readStatus: string;
    public updatedById: string;
    public updatedDateTime: Date;
    public userFromDesignation: string;
    public userReviewFromId: string;
    public userToDesignation: string;
    public userReviewToId: string;
    public feedbackType: string;
    constructor() {
        this.createdById = ""
        this.createdDateTime = new Date()
        this.feedbackSubmitStatus = FeedbackSubmitStatus.PENDING
        this.formSubmissionAnswer = ""
        this.formTemplate = ""
        this.formTemplateType = ""
        this.readStatus = FeedbackReadStatus.UNREAD
        this.updatedById = ""
        this.updatedDateTime = new Date()
        this.userFromDesignation = ""
        this.userReviewFromId = ""
        this.userToDesignation = ""
        this.userReviewToId = ""
        this.careerManagerId = ""
        this.feedbackType = ""


    }

    prepareUpdateDTOPayload(feedback:Feedback) {
        this.id = feedback.id
        this.createdById = feedback.createdBy.id;
        this.feedbackSubmitStatus = FeedbackSubmitStatus.SUBMITTED;
        this.formSubmissionAnswer = feedback.formSubmissionAnswer
        this.formTemplate = feedback.formTemplate
        this.formTemplateType = feedback.formTemplateType
        this.readStatus = FeedbackReadStatus.READ
        this.updatedById = feedback.userReviewFrom.id
        this.updatedDateTime = new Date()
        this.userFromDesignation = feedback.userFromDesignation
        this.userReviewFromId = feedback.userReviewFrom.id
        this.userToDesignation = feedback.userToDesignation
        this.userReviewToId = feedback.userReviewTo.id
        this.careerManagerId = feedback.careerManager.id
        this.feedbackType = feedback.feedbackType
    }
}