<div style="position: relative;">
    <button mat-icon-button (click)="closeDialog()" style="position: absolute;z-index: 1;  top: 15px; right: 15px;">
        <mat-icon>close</mat-icon>
    </button>
</div>
<div class="container-fluid formStyle">



    <form [formGroup]="projectForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="col"> <img class="img-style" [src]="projectLogo"> </div>
        </div>
        <div class="row">
            <div class="col form-group">
                <!-- <mat-form-field appearance="standard">
                    <mat-label>Client Name</mat-label>
                    <input matInput formControlName="client">
                </mat-form-field> -->

                <mat-form-field appearance="standard">
                    <mat-label>Client Name <span style="color: red;">*</span></mat-label>
                    <input matInput [matAutocomplete]="auto" formControlName="client">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnClient"
                        (optionSelected)='clientSelected($event.option.value)'>
                        <mat-option *ngFor="let client of filteredClient | async" [value]="client">
                            <img src="this.imageService.getImage(client.imageId)" alt=""
                                style="height: 30px; width: 30px;">
                            <span style="margin-left: 10px;">{{ client.clientName }}</span>
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="checkError('client', 'required')">required</mat-error>

                </mat-form-field>


            </div>
            <div class="col form-group">
                <mat-form-field appearance="standard">
                    <mat-label>Project Name <span style="color: red;">*</span></mat-label>
                    <input matInput formControlName="projectName" autocomplete="off">
                    <mat-error *ngIf="checkError('projectName', 'required')">required</mat-error>
                </mat-form-field>


            </div>

            <div class="col form-group">
                <mat-form-field appearance="standard">
                    <mat-label>Project Code <span style="color: red;">*</span></mat-label>
                    <input matInput formControlName="projectCode" autocomplete="off">
                    <mat-error *ngIf="checkError('projectCode', 'required')">required</mat-error>
                </mat-form-field>
            </div>

            <div class="col form-group">
                <mat-form-field appearance="standard">
                    <mat-label>Project Description <span style="color: red;">*</span></mat-label>
                    <input matInput formControlName="projectDescription" autocomplete="off">
                    <mat-error *ngIf="checkError('projectDescription', 'required')">required</mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">


            <div class="col form-group">
                <mat-form-field appearance="standard">
                    <mat-label>Department <span style="color: red;">*</span></mat-label>
                    <mat-select formControlName="department">
                        <mat-option value="API Management & Integration">API Management & Integration</mat-option>
                        <mat-option value="Data Management">Data Management</mat-option>
                        <mat-option value="Client Success">Client Success</mat-option>
                        <mat-option value="Data science & analytics">Data science & analytics</mat-option>
                        <mat-option value="Support funtion">Support funtion</mat-option>
                    </mat-select>
                    <mat-error *ngIf="checkError('department', 'required')">required</mat-error>

                </mat-form-field>
            </div>

            <div class="col form-group">
                <mat-form-field appearance="standard">
                    <mat-label>Manager <span style="color: red;">*</span></mat-label>
                    <input matInput [matAutocomplete]="auto2" formControlName="manager">
                    <mat-autocomplete #auto2="matAutocomplete" [displayWith]="displayFnManager">
                        <mat-option *ngFor="let manager of filteredManager | async" [value]="manager">
                            <span style="margin-left: 10px;">{{ manager.firstName + ' ' + manager.lastName }}</span>
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="checkError('manager', 'required')">required</mat-error>
                </mat-form-field>
            </div>

            <div class="col form-group">
                <mat-form-field appearance="standard">
                    <mat-label>Supervisor <span style="color: red;">*</span></mat-label>
                    <input matInput [matAutocomplete]="supervisorAuto" formControlName="supervisor">
                    <mat-autocomplete #supervisorAuto="matAutocomplete" [displayWith]="displayFnManager">
                        <mat-option *ngFor="let supervisor of filteredSupervisors | async" [value]="supervisor">
                            <span style="margin-left: 10px;">{{ supervisor.firstName + ' ' + supervisor.lastName
                                }}</span>
                        </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="checkError('supervisor', 'required')">required</mat-error>
                </mat-form-field>
            </div>

            <!-- <div class="col form-group">
                <mat-form-field appearance="standard">
                    <mat-label>Supervisor</mat-label>
                    <mat-select formControlName="supervisor" multiple>  
                        <mat-option *ngFor="let supervisor of this.userService.users" [value]="supervisor">{{supervisor.firstName+' '+supervisor.lastName}}</mat-option>
                    </mat-select>
                  </mat-form-field> 
                  
                  
            </div> -->


            <div class="col form-group">

                <!-- <mat-form-field appearance="standard">
                    <mat-label>Peers</mat-label>
                    <mat-select formControlName="peer" multiple>
                      <mat-option *ngFor="let peer of this.userService.users" [value]="peer">{{peer.firstName+' '+peer.lastName}}</mat-option>
                    </mat-select>
                  </mat-form-field> -->


                <mat-form-field>
                    <mat-label>Peers  <span style="color: red;">*</span></mat-label>
                    <mat-select (openedChange)="openedChange($event)" placeholder="Peers" formControlName="peer"
                        multiple>
                        <!-- <mat-select-trigger>
                            {{selectFormControl.value ? selectFormControl.value[0] : ''}}
                            <span *ngIf="selectFormControl.value?.length > 1" class="additional-selection">
                                (+{{selectFormControl.value.length - 1}} {{selectFormControl.value?.length === 2 ?
                                'other' : 'others'}})
                            </span>
                        </mat-select-trigger> -->
                       
                        <div class="select-container">
                            <mat-optgroup>
                                <mat-form-field style="width:100%;">
                                    <input #search autocomplete="off" placeholder="Search" aria-label="Search" matInput
                                        [formControl]="searchTextboxControl">
                                    <button [disableRipple]="true" *ngIf="search.value" matSuffix mat-icon-button
                                        aria-label="Clear" (click)="clearSearch($event)">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                </mat-form-field>
                            </mat-optgroup>
                            <mat-optgroup *ngIf="(filteredPeer | async).length == 0">
                                <div>No results found!</div>
                            </mat-optgroup>
                            <mat-option
                                *ngFor="let option of filteredPeer | async" [value]="option">
                                {{option.firstName + ' ' + option.lastName}}
                            </mat-option>
                        </div>
                    </mat-select>
                    <mat-error *ngIf="checkError('peer', 'required')">required</mat-error>
                </mat-form-field>
            </div>
        </div>




        <div>
            <button mat-raised-button style="background-color: #3e3e3e; color: white;" type="submit">Save</button>
        </div>
    </form>


</div>