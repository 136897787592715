<!-- <div class="container-fluid content">
    <div class="row topRow"></div>
    <div class="row mainContent">

      <div class="col-1 mainContentColSide"></div>
      <div class="col-10 mainContentColCenter">
          <app-main-content-project></app-main-content-project>
      </div>
      <div class="col-1 mainContentColSide"></div>

    </div>
    <div class="row topRow"></div>
</div> -->

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <app-main-content-project></app-main-content-project>
    </div>
  </div>
</div>