<div class="container-fluid mt-3">
    <form [formGroup]="newEmployeeDetails" (ngSubmit)="onSubmit()">

        <div class="row">
            <div class="col-md-4 col-sm-12 col-xs-12">
                <h1 class="add_emp_text mt-2">Add New Employee</h1>
            </div>
            <div class="col-md-8 col-sm-12 pr-sm-0 pr-1">
                <div class="example-button-row">
                    <button class="closeButton" (click)="closeDialog()">Close</button>
                    <button class="add_Emp_btn" type="submit"> <span class="add_em_name">Save</span> </button>
                </div>
            </div>
        </div>


        <div class="row mt-5">
            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 ">
                <div class="center">
                    <div class="rectange_lines" (click)="fileInput.click()">
                        <img class="local_server_img" [(src)]="binaryImgString" *ngIf="photo" />
                        <img class="upload_img" src="assets/Upaload.png" alt="" *ngIf="!photo" />
                        <label class="photo_label" *ngIf="!photo">+Add photo</label>
                        <input type="file" id="my_file" style="display: none;" accept=".jpg,.png" 
                        (change)="readURL($event.target.files)"
                            #fileInput />
                    </div>
                </div>
                <span class="removeImg" *ngIf="photo"  (click)="setDefaultImg()">Remove file</span>
            </div>
            <div class="col-lg-9 col-md-10 input_fields_styling">
                <div class="row ml-5 left_margin">
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>First Name <span class="contentRed">*</span></mat-label>
                            <input matInput formControlName="firstName" autocomplete="off" readonly>
                            <mat-error *ngIf="checkError('firstName', 'required')">required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Last Name</mat-label>
                            <input matInput formControlName="lastName" autocomplete="off">
                            <mat-error *ngIf="checkError('lastName', 'required')">required</mat-error>
                        </mat-form-field>

                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Employee Id <span class="contentRed">*</span></mat-label>
                            <input matInput formControlName="employeeId" autocomplete="off"
                                (ngModelChange)="onEmployeeIdTextTyped($event)">
                            <mat-error *ngIf="this.newEmployeeDetails.get('employeeId').errors?.InvalidEmployeeId">
                                {{emailError}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row ml-5 left_margin">
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Department <span class="contentRed">*</span></mat-label>
                            <mat-select formControlName="department">
                                <mat-option *ngFor="let department of this.departments" [value]="department">
                                    {{department}}
                                </mat-option>
                            </mat-select>
                            <!-- <mat-error *ngIf="checkError('department', 'required')">required</mat-error> -->
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Email <span class="contentRed">*</span></mat-label>
                            <input matInput formControlName="email" autocomplete="off"
                                (ngModelChange)="onEmailTextTyped($event)">
                            <mat-error *ngIf="this.newEmployeeDetails.get('email').errors?.InvalidEmail">
                                {{emailError}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Mobile Number <span class="contentRed">*</span></mat-label>
                            <input matInput formControlName="mobileNo" autocomplete="off">
                            <mat-error *ngIf="checkError('mobileNo', 'required')">10 digits required</mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row ml-5 left_margin">
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Job title <span class="contentRed">*</span></mat-label>
                            <mat-select formControlName="jobTitle">
                                <mat-option *ngFor="let jobTitle of this.jobTitles" [value]="jobTitle" (click)="jobTitleList(jobTitle)">{{jobTitle}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="checkError('jobTitle', 'required')">required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Career Manager</mat-label>
                            <input matInput [matAutocomplete]="careerManagerAuto" formControlName="careerManager">
                            <mat-autocomplete #careerManagerAuto="matAutocomplete"
                                [displayWith]="displayFnCareerManager">
                                <mat-option *ngFor="let manager of filteredCareerManager | async" [value]="manager">
                                    <span style="margin-left: 10px;">{{ manager.firstName + ' ' + manager.lastName
                                        }}</span>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline" class="example-full-width">
                            <mat-label>Date of joining <span class="contentRed">*</span></mat-label>
                            <input matInput [matDatepicker]="picker" placeholder="mm/dd/yyyy"
                                formControlName="dateOfJoining">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            <mat-error *ngIf="checkError('dateOfJoining', 'required')">required</mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-2 ">
            <div class="col-md-3">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Roles</mat-label>
                    <mat-select formControlName="roles" multiple>
                        <mat-option *ngFor="let role of rolesList" [value]="role">{{role}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Probation period <span class="contentRed">*</span></mat-label>
                    <input matInput formControlName="probationPeriod" autocomplete="off" readonly>
                    <mat-error *ngIf="checkError('probationPeriod', 'required')">required</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-3">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>Confirmation date <span class="contentRed">*</span></mat-label>
                    <input matInput [matDatepicker]="picker" placeholder="mm/dd/yyyy"
                        formControlName="confirmationDate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="checkError('confirmationDate', 'required')">required</mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>

