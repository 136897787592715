import { Component, OnInit, Input } from '@angular/core';
import { ChartType } from 'chart.js';
import { MultiDataSet, Label, Color } from 'ng2-charts';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.scss']
})

export class DoughnutChartComponent implements OnInit {

  @Input() averageScore: number;

  public doughnutChartLabels: Label[] = ['Average Score', ''];
  public doughnutChartColors: Color[] = [{
    backgroundColor: ["#e85d4f", "#f7eceb"]
  }];
  public doughnutChartData: MultiDataSet = [];
  public doughnutChartType: ChartType = 'doughnut';

  constructor() {
  }

  ngOnInit(): void {
    console.log("Average Score received in doughnut-chart: " + this.averageScore)
    this.doughnutChartData.push([this.averageScore, 5 - this.averageScore]);
  }

}
