<div class="container">
    <div class="row mt-2">
        <div class="col mt-1">

            <mat-tab-group #tabRef color="primary" (selectedTabChange)="goalSheetSelf(tabRef.selectedIndex)">
                

                <mat-tab label="My Goal Sheet" *ngIf='this.userDetatilService.hasRole(["ROLE_CAREER_MANAGER","ROLE_USER"])'>
                    <app-goal-sheet class="mt-2" [initialAvatar]="this.initialAvatar"
                    [userName]="this.name"></app-goal-sheet>
                </mat-tab>
                <mat-tab label="Annual Reviews" *ngIf='this.userDetatilService.hasRole(["ROLE_CAREER_MANAGER","ROLE_ADMIN"])'>
                    <app-direct-reportees-cm *ngIf='this.userDetatilService.hasRole(["ROLE_CAREER_MANAGER"])'></app-direct-reportees-cm>
                    <app-all-employee-review *ngIf='this.userDetatilService.hasRole(["ROLE_ADMIN"])'></app-all-employee-review>
                </mat-tab>
                <mat-tab label="Feedback Generation" *ngIf='this.userDetatilService.hasRole(["ROLE_CAREER_MANAGER"])'>
                    <app-feedback-generation-cm class="mt-2" ></app-feedback-generation-cm>
                </mat-tab>
                
            </mat-tab-group>

        </div>
    </div>
</div>