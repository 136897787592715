import { Component, Input, OnInit } from '@angular/core';
import { GoalStatusLabel } from 'src/app/services/goalSheet/goal-sheet-enum';
import { TimeService } from 'src/app/services/time/time.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {

  @Input() progressValue
  @Input() metric
  @Input() goalStatus
  @Input() lastUpdated
  @Input() targetValue
  @Input() userId
  @Input() year
  public lastUpdatedStatus;

  public goalStatusLabelRef = GoalStatusLabel

  
  constructor(private timeService:TimeService) { }

  ngOnInit(): void {
    
    if(this.progressValue == 43){
      this.lastUpdated
    }

    //last updated can be a string or an object of Date class
    if(this.lastUpdated && this.lastUpdated instanceof Date)
     this.lastUpdatedStatus = this.timeService.lastUpdated(this.lastUpdated)
    else
      this.lastUpdatedStatus = this.timeService.lastUpdated(new Date(this.lastUpdated))

    
  }

}
