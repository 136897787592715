import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingSource: BehaviorSubject<any> = new BehaviorSubject(false);
  private loaderCount = 0;
  constructor() { }
  onLoaderChange(): Observable<any> {
    return this.loadingSource.asObservable();
  }
  startLoading(){
    if(this.loaderCount >= 0){
      this.loaderCount += 1;
      this.loadingSource.next(true);
    }else{
      this.loaderCount = 0;
      this.loadingSource.next(false);
    }
  }
  stopLoading(){
    this.loaderCount -= 1;
    if(this.loaderCount == 0){
      this.loadingSource.next(false);
    }
  }
  resetLoading(){
    this.loaderCount = 0;
    this.loadingSource.next(false);
  }
}
