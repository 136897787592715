import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { UserService } from 'src/app/services/user/user.service';
import { InteractionService } from 'src/app/services/interaction.service';
import { UitoggleService } from 'src/app/services/uitoggle.service';
import { AddEmployeeCardComponent } from './add-employee-card/add-employee-card.component';
import { UserDetailsService } from 'src/app/services/user-details.service';
// import html2canvas from 'html2canvas';
// import { jsPDF } from 'jspdf';
// import 'jspdf-autotable';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent implements OnInit {
  isExpanded:Boolean
  empDetail;
  searchText: string;
  display:string
  matTabsClickedIndex= 0 
  hasRoleBased:any;
  constructor(
    public userDetailService: UserDetailsService,
    public employeeServie: UserService,
    public initialAvatarService: InitialAvatarService,
    private dialog: MatDialog,
    private interaction:InteractionService,
    private uitoggle:UitoggleService) { }


  ngOnInit(): void {
    this.hasRoleBased = this.userDetailService.hasRole(['ROLE_USER'])
    this.interaction.cast.subscribe(data => this.isExpanded=data);
    this.uitoggle.cast.subscribe(data => this.display=data);
   
  }
 
  changevalue(){
    this.isExpanded=!this.isExpanded;
    this.interaction.changestate(this.isExpanded);

  }
  changeDisplay(value:string){
    this.display = value;
    this.uitoggle.changestate(this.display);
  }

  onSearchTextTyped(event) {
    if (event == "") {
      event = ""
      this.searchText = ""
      this.employeeServie.usersDto = [];
      this.employeeServie.getUser();
    } else {
      this.employeeServie.usersDto = [];
      this.employeeServie.searchUser(event);
      this.empDetail = this.employeeServie.usersDto;
    }
  }

  addEmployeeDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "70%";
    dialogConfig.height = "60%";
    // dialogConfig.data = this.project;
    const dialogRef = this.dialog.open(AddEmployeeCardComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }


  onTabChanged($event) {
     this.matTabsClickedIndex = $event.index;
  }

  createpdf() 
  {
     var data = document.getElementById('content');
     debugger;
    //  html2canvas(data).then(canvas => {
    //   canvas.getContext('2d');
    //    var HTML_Width = canvas.width;
    //   var HTML_Height = canvas.height;
    //   var top_left_margin = 15;
    //   var PDF_Width = HTML_Width+(top_left_margin*2);
    //   var PDF_Height = (PDF_Width*1.5)+(top_left_margin*2);
    //   var canvas_image_width = HTML_Width;
    //   var canvas_image_height = HTML_Height;
      
    //   var totalPDFPages = Math.ceil(HTML_Height/PDF_Height)-1;
    //   console.log(canvas.height+"  "+canvas.width);
      
    //   var imgData = canvas.toDataURL("image/jpeg", 1.0);
    //   var pdf = new jsPDF('p', 'pt',  [PDF_Width, PDF_Height]);
    
      
    //   pdf.setFontSize(12);  
      
    //   pdf.addImage(imgData, 'PNG', top_left_margin, top_left_margin,canvas_image_width,canvas_image_height);
      
    
    //   for (var i = 1; i <= totalPDFPages; i++) { 
    //   pdf.addPage();
    //   //var totalPages = pdf.internal.pages();
    //   let margin=-(PDF_Height*i)+(top_left_margin*4);
    //   if(i>1)
    //   {
    //   margin=margin+i*8;
    //   }
    //   console.log(top_left_margin);
    //   console.log(top_left_margin);
    //   console.log(-(PDF_Height*i)+(top_left_margin*4));
    //   pdf.addImage(imgData, 'JPG', top_left_margin, margin,canvas_image_width,canvas_image_height);
    //  }
    //      pdf.save("UnmarkedEmployee.pdf");
    //        });
    //   };
  }

}
