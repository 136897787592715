import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FeedbackUrlService {


  private readonly feedbackBaseApi = '/api/feedbacks';
  private readonly user = '/user';
  private readonly careerManager = '/careerManager'
  private readonly careerManagerId_param = 'careerManagerId'
  private readonly userFrom_param = 'userReviewFrom'
  private readonly feedbackSubmitStatus_param = 'feedbackSubmitStatus'
  private readonly readStatus_param = 'readStatus'
  private readonly averageScore = "/api/users/"
  private readonly userAverage = "/user-avg"
  private readonly emailReminder = "/emailReminder";
  private readonly  userReviewFromId_param = 'userReviewFromId';
  private readonly  userReviewToId_param = 'userReviewToId';

  


  constructor() {
  }

  getBaseUrl() {
    return this.feedbackBaseApi;
  }

  //This will be used for get by id and delete by id
  getByFieldUrl(field) {
    return this.feedbackBaseApi + this.getByField(field)
  }

  private getByField(field) {
    return '/' + field;
  }

  getFeedBackByUserIdAndStatusUrl(userFrom, readStatus, feedbackSubmitStatus) {
    return this.feedbackBaseApi + this.user
      + '?' + this.feedbackSubmitStatus_param + '=' + feedbackSubmitStatus
      + '&' + this.readStatus_param + '=' + readStatus
      + '&' + this.userFrom_param + '=' + userFrom

  }

  getCareerManagerUrl(careerManagerId) {
    return this.feedbackBaseApi + this.careerManager
      + '?' + this.careerManagerId_param + '=' + careerManagerId
  }

  getAverageScoreUrl(userReviewTo, year) {
    console.log("Average score API:" + this.averageScore + userReviewTo + "/" + year + this.userAverage)
    return this.averageScore + userReviewTo + "/" + year + this.userAverage
  }

  getEmailReminderUrl(userReviewTo ,userReviewFrom) {
// http://localhost:8080/api/feedbacks/emailReminder?userReviewFromId=620e19723b1b3f183f7b424e&userReviewToId=6204d0a522f1536f4f0c4f18
    return this.feedbackBaseApi + this.emailReminder + '?' + this.userReviewFromId_param + '=' + userReviewFrom + '&' + this.userReviewToId_param + '=' + userReviewTo;
  }

}
