import { Component, Input, OnInit } from '@angular/core';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';
@Component({
  selector: 'app-horizontal-bar-chart',
  templateUrl: './horizontal-bar-chart.component.html',
  styleUrls: ['./horizontal-bar-chart.component.scss']
})
export class HorizontalBarChartComponent implements OnInit {

  @Input() individualScores: any;

  public barChartOptions: ChartOptions = {

    responsive: true,
    scales: {
      xAxes: [{
        gridLines: {
          // display: false
        },
        ticks: {
          stepSize: 1,
          max: 5,
          min: 0
        }
      }],
      yAxes: [{
        gridLines: {
          display: false
        }
      }]
    }
    ,
    animation: {
      onComplete: function () {
        var chartInstance = this.chart,
          ctx = chartInstance.ctx;
        this.data.datasets.forEach(function (dataset, i) {
          var meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            var data = dataset.data[index];
            if (data > 0) {
              ctx.fillText(data, bar._model.x, bar._model.y + 5);
            }
          });
        });
      }
    }
  };

  public barChartType: ChartType = 'horizontalBar';

  public barChartLegend = false;

  public doughnutChartColors: Color[] = [{
    backgroundColor: ['#aeebe9', "#8eedea", '#6dede9', '#47ede8', '#1eebe4']
  }];

  public barChartData: ChartDataSets[] = [{ data: [] }];
  public barChartLabels: string[] = ['Career Manager Score', 'Client Score', 'Supervisor Score', 'Peer Score', 'Manager Score'];

  constructor() { }

  ngOnInit(): void {
    this.barChartData[0].data = [this.individualScores.careerMgrAvgScore, this.individualScores.clientAvgScore,
    this.individualScores.supervisorAvgScore, this.individualScores.peerAvgScore, this.individualScores.managerAvgScore]
  }
  public chartHovered({
    event,
    active,
  }: {
    event: MouseEvent;
    active: {}[];
  }): void {
    console.log(event, active);
  }

}
