export class AverageScoreDTO {
    public overallAvgScore: Number;
    public clientAvgScore: Number;
    public careerMgrAvgScore: Number;
    public managerAvgScore: Number;
    public peerAvgScore: Number;
    public supervisorAvgScore: Number;

    constructor() {
        this.overallAvgScore = 0;
        this.clientAvgScore = 0;
        this.careerMgrAvgScore = 0;
        this.managerAvgScore = 0;
        this.peerAvgScore = 0;
        this.supervisorAvgScore = 0;

    }
}

