import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CircleLoaderService {

  private count = 0;
  private cirleLoader$ = new BehaviorSubject<string>('');

  constructor() { }

  getCircleLoaderObserver(): Observable<string> {
    return this.cirleLoader$.asObservable();
  }

  requestStarted() {
    if (++this.count === 0) {
      this.cirleLoader$.next('start');
    }
  }

  requestEnded() {
    if (this.count === 0 || --this.count === 0) {
      this.cirleLoader$.next('stop');
    }
  }

  resetCircleLoader() {
    this.count = 0;
    this.cirleLoader$.next('stop');
  }
}
