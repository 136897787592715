import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InteractionService {

  public state = new BehaviorSubject<Boolean>(false);
  cast =this.state.asObservable();



  constructor() { }

   changestate(data){
     this.state.next(data);

   }


}
