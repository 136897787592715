import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { UserService } from 'src/app/services/user/user.service';
import { AddEmployeeCardComponent } from '../add-employee-card/add-employee-card.component';
import { InteractionService } from 'src/app/services/interaction.service';
import { UitoggleService } from 'src/app/services/uitoggle.service';
@Component({
  selector: 'app-main-content',
  templateUrl: './main-content.component.html',
  styleUrls: ['./main-content.component.scss']
})
export class MainContentComponent implements OnInit {
  isExpanded:Boolean
  empDetail;
  searchText: string;
  display:string

  constructor(public employeeServie: UserService,
    public initialAvatarService: InitialAvatarService,
    private dialog: MatDialog,
    private interaction:InteractionService,
    private uitoggle:UitoggleService) { }


  ngOnInit(): void {
   
    this.interaction.cast.subscribe(data => this.isExpanded=data);
    this.uitoggle.cast.subscribe(data => this.display=data);
    this.employeeServie.getUserSubscribe().subscribe(res => {
      //get rest data and convert it to userDTO
      this.employeeServie.setUsersDto(res.body);
      this.empDetail = this.employeeServie.usersDto;
    })
    if(this.employeeServie.allUserData.length==0){
      this.employeeServie.getAllUser()
    }
  }
 
  changevalue(){
    this.isExpanded=!this.isExpanded;
    this.interaction.changestate(this.isExpanded);

  }
  changeDisplay(value:string){
    this.display = value;
    this.uitoggle.changestate(this.display);

  }

  onSearchTextTyped(event) {
    if (event == "") {
      event = ""
      this.searchText = ""
      this.employeeServie.usersDto = [];
      this.employeeServie.getUser();
    } else {
      this.employeeServie.usersDto = [];
      this.employeeServie.searchUser(event);
      this.empDetail = this.employeeServie.usersDto;
    }
  }

  addEmployeeDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = "70%";
    dialogConfig.height = "60%";
    // dialogConfig.data = this.project;
    const dialogRef = this.dialog.open(AddEmployeeCardComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }


}
