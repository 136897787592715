import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterLink, RouterStateSnapshot } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Client } from '@microsoft/microsoft-graph-client';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import { OAuthSettings } from 'src/oauth';
import { UserDTO } from '../dtos/UserDTO';
import { AlertsService } from './alerts.service';
import { ApiService } from './api.service';
import { BadgeService } from './leftMenuBar/badge.service';
import { SidenavService } from './sidenav.service';
import { SpinnerService } from './spinner.service';
import { StorageService } from './storage.service';
import { UserDetailsService } from './user-details.service';
import { User } from './user/user';
import { LoginType } from './login/login-enum';
import { LoginService } from './login.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  
  public static authenticated: boolean = false;
  public user: User;
  isLoggedIn = false;
  loggedInUser;
  userDetails = new UserDTO();
  constructor(
    private badgeService:BadgeService,
    private router: Router,
    private storageService: StorageService,
    private loginService: LoginService,
    private msalService: MsalService,
    private alertsService: AlertsService,
    private apiService: ApiService,
    private spinnerService: SpinnerService,
    private userDetailsService: UserDetailsService,
    private sidenavService:SidenavService) {
  }
  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    if (this.userDetailsService.getUserDetails() == undefined){
      this.userDetailsService.setUserDetails( 
        JSON.parse(this.storageService.getItem(StorageService.USER_INFO)));

    }

    if (AuthGuardService.authenticated == false) {
      if(this.storageService.getItem(StorageService.LOGIN_TYPE) == LoginType[LoginType.MicrosoftSSOLogin]){
      AuthGuardService.authenticated = this.msalService.getAccount() != null;
      this.getUser().then((user) => { this.user = user });
      return AuthGuardService.authenticated ? true : false;
      }else if(this.storageService.getItem(StorageService.LOGIN_TYPE) == LoginType[LoginType.NormalLogin]){
        this.loginService.updateLoggedinUserInfo();
        AuthGuardService.authenticated = true;
        return AuthGuardService.authenticated ? true : false;

      }
    }
    else {
      let loggedinUserType = this.storageService.getItem(
        StorageService.LOGGED_IN_USER_TYPE);
      let loggedinUserInfo = JSON.parse(this.storageService.getItem(StorageService.LOGGED_IN_USER_INFO));
      if (loggedinUserInfo == null) {
        this.loginService.isLoggedIn = false;
        this.router.navigate(['/login']);
        return false;
      } else {
        this.loginService.isLoggedIn = true;
        this.loginService.loggedInUser = loggedinUserInfo;
        return true;
      }
    }
  }
  async signIn(): Promise<void> {
    let result = await this.msalService.loginPopup(OAuthSettings)
      .catch((reason) => {
        this.alertsService.addError('Login failed', JSON.stringify(reason, null, 2));
      });
    if (result) {
      if (!AuthGuardService.authenticated) {
        this.user = await this.getUser();
      }
    }
  }
  async getAccessToken(): Promise<string> {
    let result = await this.msalService.acquireTokenSilent(OAuthSettings)
      .catch((reason) => {
        this.alertsService.addError('Get token failed', JSON.stringify(reason, null, 2));
      });
    if (result) {
      AuthGuardService.authenticated = true;
      this.spinnerService.requestStarted();
      this.apiService.msLogin(result.accessToken).subscribe(res => {
        this.spinnerService.requestEnded()
        this.loginSuccess(res,LoginType[LoginType.MicrosoftSSOLogin])
       
      });
      return result.accessToken
    }
    AuthGuardService.authenticated = false;
    this.router.navigate(['/']);
    return null;
  }
  signOut() {
   
    if(this.storageService.getItem(StorageService.LOGIN_TYPE) == LoginType[LoginType.MicrosoftSSOLogin])
      this.msSignOut().then(res=>{
        this.logoutSuccess()
      }).catch(err=>{
        this.getUser().then((user) => { this.user = user });
      });
    else{
      this.logoutSuccess()
    }

    

    
    // location.reload();
  }

  async msSignOut(): Promise<void>{
    await this.msalService.logout();
  }
  private async getUser(): Promise<User> {
    if (AuthGuardService.authenticated) {
      this.loginService.updateLoggedinUserInfo();
      return null
    };
    let graphClient = Client.init({
      authProvider: async (done) => {
        let token = await this.getAccessToken()
          .catch((reason) => {
            done(reason, null);
          });
        if (token) {
          done(null, token);
        } else {
          done("Could not get an access token", null);
        }
      }
    });
    let graphUser: MicrosoftGraph.User = await graphClient
      .api('/me')
      .select('displayName,mail,mailboxSettings,userPrincipalName')
      .get();
    let user = new User();
    user.displayName = graphUser.displayName;
    user.email = graphUser.mail || graphUser.userPrincipalName;
    user.timeZone = graphUser.mailboxSettings.timeZone;
    user.avatar = 'assets/no-profile-photo.png';
    return user;
  }

  async loginSuccess(res,loginType){
    this.loggedInUser = res;
    this.storageService.setItem(StorageService.JWT_TOKEN, res.body.id_token);
    this.storageService.setItem(StorageService.REFRESH_TOKEN, res.body.refresh_token);
    this.storageService.setItem(StorageService.LOGGED_IN_USER_INFO, JSON.stringify(res.body.username));
    this.storageService.setItem(StorageService.LOGIN_TYPE, loginType);
    
    this.loginService.updateLoggedinUserInfo();
    // this.router.navigate(['create']);
    // Note : change this apiservice to RestApiService. By creating Account service and manage all call there.
    // We don't need to send token in param this is old code. Now we have implemented intercepter which will automatically inject token into api header.
    await this.apiService.getUserAccount().subscribe(res => {
      this.userDetails = res;
      this.storageService.setItem(StorageService.USER_INFO,JSON.stringify(res));
      this.userDetailsService.setUserDetails(res);
      this.loginService.updateLoggedinUserInfo();
      // this.sidenavService.open()
      //this.badgeService.getPendingFeedbackCount();
      if (this.userDetailsService.hasRole(["ROLE_ADMIN"])) {
        this.router.navigate(['project']);
      } else if(this.userDetailsService.hasRole(["ROLE_CAREER_MANAGER"])){
        this.router.navigate(['careerManager']);

      }else{
        this.router.navigate(['feedback-submission']);
      }
    })
  }

  logoutSuccess(){
    this.user = null;
    AuthGuardService.authenticated = false;
    this.isLoggedIn = false;
    this.loggedInUser = null;
    this.userDetailsService.setUserDetails(undefined);
    this.storageService.setItem(StorageService.IS_LOGGED_OUT, "true");
    this.storageService.removeItem(StorageService.ACCESS_TOKEN);
    this.storageService.removeItem(StorageService.LOGGED_IN_USER_INFO);
    this.storageService.removeItem(StorageService.JWT_TOKEN);
    this.storageService.removeItem(StorageService.REFRESH_TOKEN);
    this.storageService.removeItem(StorageService.USER_INFO);
    this.storageService.removeItem(StorageService.LOGIN_TYPE)
    //this.userDetailsService.setUserDetails(undefined)
    this.router.navigate(['']);
  }
  async loginGetToken(loginPass: any) {
    debugger
    this.spinnerService.requestStarted()
      this.apiService.getAuthenticationToken(loginPass).subscribe(res => {
      
      AuthGuardService.authenticated = true;
      this.loginSuccess(res,LoginType[LoginType.NormalLogin])
      this.spinnerService.requestEnded()
    })
  }
}
