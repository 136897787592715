export enum JobTitles{
    MANAGING_DIRECTOR = "Managing Director",
    DIRECTOR = "Director",
    SR_MANAGER = "Sr. Manager",
    MANAGER = "Manager",
    SR_CONSULTANT = "Sr. Consultant",
    CONSULTANT = "Consultant",
    ANALYST = "Analyst"
}

export enum AuthoritiesConstants {

    ROLE_ADMIN = "Role Admin",
    ROLE_USER = "Role User",
    ROLE_CAREER_MANAGER = "Role Career Manager",
    ROLE_SUPER_ADMIN = "Super Admin"
}

export enum Departments{
    API_MANAGEMENT_AND_INTEGRATION = "Api Management & Integration",
    DATA_MANAGEMENT = "Data Management",
    CLIENT_SUCCESS = "Client Success",
    DATA_SCIENCE_AND_ANALYTICS = "Data Science and analytics",
    GEOGRAPHY = "Geography",
    SUPPORT_FUNCTION = "Support Function",
    SUPPORT_FUNCTIONS_HK = "Support function (House Keeping)",
    SUPPORT_FUNCTIONS_MS = "Support Functions (Marketing and Sales)",
    SUPPORT_FUNCTIONS_OPS = "Support Functions (Operations)",
    SUPPORT_FUNCTIONS_PT = "Support Functions (People & Talent)"
}