<div class="row mt-5 mb-5">
    <mat-accordion class="w-100">

        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header class="expansionPanelHeader">
                <div class="container-fluid">
                    <div class="row heaaderRow">

                        <div class="col-1 colImageDiv">

                            <img class="img-style" [src]="this.initialAvatar">
                        </div>
                        <div class="col-5">
                            <p class="h5">{{goalTypeLabel}}</p>

                            <p>


                                <small class="text-muted">
                                    {{this.userName}}
                                </small>
                            </p>

                        </div>
                        <div class="col-2"></div>
                        <div class="col-4">
                            <!-- <div class="row ">
                                <div class="col text-left">30/100%</div>
                                <div class="col text-right">18 minutes ago</div>
                            </div>
                            <div class="row">
    
                                <div class="col">
                                    <div class="overallProgressBar">
    
                                        <div class="progress">
                                            <div class="progress-bar bg-success" role="progressbar" style="width: 25%" aria-valuenow="30"
                                                aria-valuemin="0" aria-valuemax="100">30%</div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <app-progress-bar [lastUpdated]="this.goalService.subGoalsData[this.goalType].lastUpdated" [progressValue]="this.goalService.subGoalsData[this.goalType].progress" [targetValue]="100" ></app-progress-bar>
                        </div>


                    </div>
                </div>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col" style="text-align: right;">
                    <button *ngIf="this.activityType == 'Edit'" style="color: #007bff;" mat-button (click)="addSubGoal()">Add Sub Goals</button>
                </div>
            </div>
            <app-sub-goal-tab *ngFor="let goal of this.goalService.subGoalsData[this.goalType].subGoalList" [goal]="goal" [goalTypeLabel]="this.goalTypeLabel" [activityType]="this.activityType" [userName]="this.userName" [initialAvatar]="this.initialAvatar"></app-sub-goal-tab>
        </mat-expansion-panel>
    </mat-accordion>
</div>