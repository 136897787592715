import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { interval, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { BadgeService } from 'src/app/services/leftMenuBar/badge.service';
import { LoginService } from 'src/app/services/login.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { animateText, onSideNavChange } from '../../animations/animation';
import { SidenavService } from '../../services/sidenav.service';
import { InteractionService } from 'src/app/services/interaction.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SpinnerService } from 'src/app/services/spinner.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent implements OnInit {

  isExpanded: Boolean
  adminName : string;

  constructor(
    public loginService: LoginService,
    public userDetail:UserDetailsService,
    private authguardService:AuthGuardService,
    private router: Router

   ) { }
  ngOnInit(): void {
    this.getAdminName();
  }

  getAdminName() {
    this.adminName = this.userDetail.getUserDetails().firstName +" "+ this.userDetail.getUserDetails().lastName 
  }
  
  togglebutton() {
    this.isExpanded = !this.isExpanded
  }
  
  logOut() {
    alert('logout')
    this.authguardService.signOut()
  }


}
