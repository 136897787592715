import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { LoginService } from 'src/app/services/login.service';
import { SidenavService } from 'src/app/services/sidenav.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { SpinnerComponent } from '../spinner/spinner.component';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hide = true;
  loginPasswordFormGroup: FormGroup;
  constructor(private formBuilder: FormBuilder,
    private spinnerService: SpinnerService,
    private loginService: LoginService,
    private authGuardService : AuthGuardService,
    private sidenavService: SidenavService) { }
  ngOnInit(): void {
    this.createLoginWithPasswordForm();
  }
  createLoginWithPasswordForm() {
    this.loginPasswordFormGroup = this.formBuilder.group({
      'email': [null, [Validators.required]],
      'password': [null, [Validators.required]]
    });
  }
  onClickLoginPassword(loginPass) {
    this.loginService.loginWithPassword(loginPass.email);
  } 
  async onClickLoginGetToken(){ // no need to pass parameter. Check if loginPasswordFormGroup is valid. If yes call loginGetToken With it.
    
    if(this.loginPasswordFormGroup.valid){
      await this.authGuardService.loginGetToken(this.loginPasswordFormGroup.value);
      

    }
 
  }
  onClickGetProjects(){
    this.loginService.getAllProjects();
  }
  onClickGetAuthority(){
    this.loginService.getAuthority();
  }
  async signIn(): Promise<void> {
    await this.authGuardService.signIn();
  }
}
