import { Injectable } from '@angular/core';
import { RestAPIService } from './restAPI/rest-api.service';

@Injectable({
  providedIn: 'root'
})
export class TitleService {

    constructor(private restApiService: RestAPIService) {

    }

  getAllTitleList() {
      return this.restApiService.get("/api/titles")
  }

}
