import { GoalStatus, GoalType, Metric } from 'src/app/services/goalSheet/goal-sheet-enum'
import { Goal } from '../models/goal';
import { UserDetailsService } from '../services/user-details.service';

export class GoalDTO {
    id: string;
    currentValue: number
    endDate: Date
    goalSheetId: string
    goalStatus: GoalStatus
    goalType: GoalType
    initialValue: string
    metric: Metric
    startDate: Date
    targetValue: string
    timeFrame: Date
    title: string
    createdDate: Date
    userId: string
    lastUpdated: Date
    description: string
    constructor() {
        this.currentValue = 0
        this.endDate = new Date(new Date().getFullYear(), 11, 31)
        this.goalStatus = GoalStatus.NotStarted
        this.goalType = null
        this.initialValue = '0'
        this.metric = null
        this.startDate = new Date();
        this.targetValue = ""
        this.timeFrame = this.endDate
        this.title = ''
        this.createdDate = new Date()
        this.lastUpdated = new Date()
        this.description = ''
        this.userId = null

    }

    processDto(goal: Goal) {
        this.id = goal.id
        this.createdDate = new Date(goal.createdDate)
        this.currentValue = goal.currentValue
        this.description = goal.description
        this.endDate = new Date(goal.endDate)
        this.goalSheetId = goal.goalSheetId || null
        this.goalStatus = goal.goalStatus
        this.goalType = goal.goalType
        this.initialValue = goal.initialValue
        this.lastUpdated = new Date()
        this.metric = Metric[goal.metric]
        this.startDate = new Date(goal.startDate)
        this.targetValue = goal.targetValue
        this.timeFrame = new Date(goal.timeFrame)
        this.title = goal.title
        if (goal.user) {
            this.userId = goal.user.id
        }



    }
}