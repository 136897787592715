<app-angular-spinner></app-angular-spinner>
<div class="mainContainer shadow-lg">
    <div class="row progressCard mt-5">
        <div class="col-2">
            <div class="overallProgressText">
                <p class="h5">Overall Progress</p>
            </div>
            <div class="overallProgressBar">
                
                <div class="progress">
                    <div class="progress-bar bg-success" role="progressbar" [style.width]="this.goalService.overallProgressData.progress+'%'" aria-valuenow="30"
                        aria-valuemin="0" aria-valuemax="100"> <span *ngIf="this.goalService.overallProgressData.progress>10">{{this.goalService.overallProgressData.progress}}%</span> </div>
                </div>
                
                <p class="mt-2"><small class="text-muted">Last Updated 18 minutes ago</small> </p>
            </div>
        </div>
        <div class="col-8">
            <div class="row">
                <div class="col-2 trackStatusBlock">
                    <div>
                        <span class="circle circleColorGreen"></span><span class="trackStatusText text-muted">ON
                            TRACK</span>
                    </div>
                    <div class="trackStatusCountDiv">
                        <span class="trackStatusCount">{{this.goalService.overallProgressData.goalStatusStateCount.get(this.goalStatusEnumRef.OnTrack)}}</span>
                    </div>
                </div>
                <div class="col-3 trackStatusBlock">
                    <div>
                        <span class="circle circleColorYellow"></span><span class="trackStatusText text-muted">NEED
                            ATTENTION</span>
                    </div>
                    <div class="trackStatusCountDiv">
                        <span class="trackStatusCount">{{this.goalService.overallProgressData.goalStatusStateCount.get(this.goalStatusEnumRef.NeedsAttention)}}</span>
                    </div>
                </div>
                <div class="col-2 trackStatusBlock">
                    <div>
                        <span class="circle circleColorRed"></span><span class="trackStatusText text-muted">AT
                            RISK</span>
                    </div>
                    <div class="trackStatusCountDiv">
                        <span class="trackStatusCount">{{this.goalService.overallProgressData.goalStatusStateCount.get(this.goalStatusEnumRef.AtRisk)}}</span>
                    </div>
                </div>
                <div class="col-3 trackStatusBlock">
                    <div>
                        <span class="circle circleColorGrey"></span><span class="trackStatusText text-muted">NOT
                            STARTED</span>
                    </div>
                    <div class="trackStatusCountDiv">
                        <span class="trackStatusCount">{{this.goalService.overallProgressData.goalStatusStateCount.get(this.goalStatusEnumRef.NotStarted)}}</span>
                    </div>
                </div>
                <div class="col-2 trackStatusBlock">
                    <div>
                        <span class="circle circleColorGrey"></span><span
                            class="trackStatusText text-muted">CLOSED</span>
                    </div>
                    <div class="trackStatusCountDiv">
                        <span class="trackStatusCount">{{this.goalService.overallProgressData.goalStatusStateCount.get(this.goalStatusEnumRef.Closed)}}</span>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-2">
            <div>
                <div class="selectYearBlock">
                    <div class="text-center yearTextDiv"><span class="yearText">Time Frame</span></div>
                    <div class="yearValueDiv text-center">

                        <div class=" d-inline-block"> <span class="trackStatusText text-muted">Jan-{{this.year}} To Dec-{{this.year}}</span> </div>
                        <!-- <div class="d-inline-block">
                            <mat-icon class="text-muted" aria-hidden="false" aria-label="Example home icon">expand_more
                            </mat-icon>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>

    <app-goal-sheet-accordian *ngFor="let item of this.goalTypeEnumRef | keyvalue" [goalType]="item.value" [goalTypeLabel]="this.goalCategory[item.value]" [userId]="this.userId" [year]="this.year" [activityType]="this.activityType" [userName]="this.userName" [initialAvatar]="this.initialAvatar"></app-goal-sheet-accordian>
    
</div>

