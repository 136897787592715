import { Injectable } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { retry, share, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FeedbackSubmitStatus, FeedbackReadStatus } from '../feedback/feedback-enum';
import { FeedbackService } from '../feedback/feedback.service';
import { UserDetailsService } from '../user-details.service';

@Injectable({
  providedIn: 'root'
})
export class BadgeService {
  feedbackBadgeCount: string;
  feedbackBadgeCountObservable:Observable<any>
  stopPolling = new Subject();
  constructor(private userDetail:UserDetailsService,
              private feedbackService:FeedbackService) {
                //for api polling to work perfectly first check if the connection to backend is available if yes then call the api if no then poll the backend if the connection is available again and the call the api
                this.feedbackBadgeCountObservable = timer(1,2000).pipe(
                                          switchMap(()=> (this.userDetail.getUserDetails().microsoftId?this.feedbackService.getFeedbackByUserIdAndStatus(this.userDetail.getUserDetails().id, FeedbackReadStatus.UNREAD, FeedbackSubmitStatus.PENDING):undefined))
                                           ,retry(),
                                           tap(console.log),
                                           share(),
                                           takeUntil(this.stopPolling)
                                            )      

               }

  getPendingFeedbackCount(){
    console.log(this.userDetail.getUserDetails())
    if (this.userDetail.getUserDetails().microsoftId != undefined) {
      console.log("api calling")
      return this.feedbackService.getFeedbackByUserIdAndStatus(this.userDetail.getUserDetails().id, FeedbackReadStatus.UNREAD, FeedbackSubmitStatus.PENDING)
    }
    
    return 
  }

  ngOnDestroy() {
    this.stopPolling.next();
}
}
