<div class="container-fluid">
    <div mat-dialog-title>
        <div class="row">
                <div class="col-md-6 ">
                    <h2 class="mt-3">Generate Feedback</h2>
                </div>
                <div class="col-md-6">
                    <button mat-icon-button (click)="onClose()" class="mat-icon-close">
                        <mat-icon class="closebtn">close</mat-icon>
                    </button>
                </div>
        </div>
        <hr>
    </div>
    <form [formGroup]="multipleFeedbackGenerationArray" (ngSubmit)="onSubmit()">
        <mat-dialog-content class="mat-typography">
            <div class="row">
                <div class="col-md-12">
                    <button matTooltip="Add" mat-mini-fab (click)="addFeedbackGeneration()" class="mat-icon-add">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="formStyle bg-light">
                        <div class="row shadow-lg mt-3 bg-white rounded" formArrayName="feedbacks"
                            *ngFor="let feedback of this.feedbacks().controls; let i = index">
                            <div class="col-10">
                                <div class="row">
                                    <div class="col" [formGroupName]="i">
                                        <mat-form-field appearance="standard">
                                            <mat-label>Select a Form <span class="contentRed">*</span></mat-label>
                                            <mat-select formControlName="formTemplate">
                                                <mat-option *ngFor="let form of this.formService.forms" [value]="form">
                                                    {{form.formType}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="checkError">required</mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="col" [formGroupName]="i">
                                        <mat-form-field appearance="standard">
                                            <mat-label>Select feedback type<span class="contentRed">*</span>
                                            </mat-label>
                                            <mat-select formControlName="feedbackType">
                                                <mat-option *ngFor="let form of this.feedbackTypeEnum" [value]="form">
                                                    {{form}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="checkError">required</mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="col" [formGroupName]="i">
                                        <mat-form-field appearance="standard">
                                            <mat-label>Select Reviewer<span class="contentRed">*</span></mat-label>
                                            <mat-select formControlName="directReportee">
                                                <mat-option *ngFor="let user of this.userService.directReportees"
                                                    [value]="user">
                                                    {{processName(user)}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="checkError">required</mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="col" [formGroupName]="i">
                                        <mat-form-field appearance="standard">
                                            <mat-label>Select Reviewee<span class="contentRed">*</span></mat-label>
                                            <mat-select formControlName="employees">
                                                <mat-option *ngFor="let user of this.userService.allUserData"
                                                    [value]="user">
                                                    {{processName(user)}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="checkError">required</mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="mt-4">
                                    <button type="button" mat-mini-fab color="warn" (click)="removeFeedback(i)">
                                        <i class="fas fa-minus"></i>
                                    </button>
                                    <button type="button" mat-icon-button   class="btn btn-success btn small ml-5"  (click)="panelH._toggle()">
                                        <mat-icon aria-hidden="false" aria-label="Preview form">expand_more</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </mat-dialog-content>

        <mat-dialog-actions align="center">
            <div class="formStyle mt-4">
                <button mat-raised-button class="submit-button" type="submit">Submit</button>
            </div>
        </mat-dialog-actions>
    </form>
</div>