import { Injectable } from '@angular/core';
import { Attendance, IAttendance } from '../../models/attendance';
import { Router } from '@angular/router';
import { RestAPIService } from '../restAPI/rest-api.service';
import { Observable } from 'rxjs';
import { AnyRecord } from 'dns';
import { UserDetailsService } from '../user-details.service';
import { AttendanceUrlService } from '../attendanceURLs/attendance-url.service';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {
  public attendanceDto: Attendance[];
  
  constructor(private restApiService: RestAPIService,
              private userDetailService:UserDetailsService,
              private attendanceUrl:AttendanceUrlService) { }
  
  SendAttendance(payload:object){
    return this.restApiService.post('/api/attendances',payload)
  }//
  getAttendanceForLoggerInUser(){
    let url = '/api/attendances'+'/'+this.userDetailService.getUserDetails().id+'/'+[String(new Date().getDate()).padStart(2,'0'),(String(new Date().getMonth() + 1)).padStart(2,'0'),(new Date().getFullYear())].join('-')
    console.log(url)
    return this.restApiService.get(url) //dd-MM-yyyy
  }

  getAttendance(attendanceStatus,date)
  {return this.restApiService.get(
   this.attendanceUrl.getAttendanceUrl(attendanceStatus,date)
  )
 }
  
}
