import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private messageService:MessageService) { }

  sucess(message){
    this.messageService.add(
      {
        severity: 'success',
        summary: 'Success',
        detail: message
      }
    )
    
    
  }

  info(message){
    this.messageService.add(
      {
        severity: 'info',
        summary: 'Info',
        detail: message
      }
    )
    
    
  }

  warn(message){
    this.messageService.add(
      {
        severity: 'warn',
        summary: 'Warning',
        detail: message
      }
    )
    
    
  }

  error(message){
    this.messageService.add(
      {
        severity: 'error',
        summary: 'Error',
        detail: message
      }
    )
    
    
  }

  invalidFormData(){
    this.warn("Invalid Form Data Entry")
  }
}
