import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EvaluationConfigurationURLsService {

  private readonly evaluationBaseApi = '/api/evaluation-configurations';
  
  constructor() { }

  getBaseUrl(){
    return this.evaluationBaseApi;
  }

  getByFieldUrl(field){
    return this.evaluationBaseApi + this.getByField(field)
  }

  private getByField(field){
    return '/'+field;
  }
}
