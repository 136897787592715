<div class="row contentRow">
    <div class="col contentCol">
        <div class="col-8 align-item-center">
            <div class="wrapper">
                <div class="searchBar">
                    <input id="searchQueryInput" type="text" name="searchQueryInput"  (ngModelChange)="onSearchTextType($event)" [ngModel] = "searchText" placeholder="Search Performance Form"
                        value=""/>
                    <button id="searchQuerySubmit" type="submit" name="searchQuerySubmit">
                        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="#666666"
                                d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <div class="row p-2" *ngFor="let employeeReview of this.employeeReviewService.employeeReviewList">
            <div class="container-fluid cardContent" *ngIf="employeeReview.employee.jobTitle!=null">
                <div class="row">
                    <div class="col-1 col-sm-1 justify-content-center"><img class="img-style"
                            [src]="this.initialAvatarService.getInitialImage(employeeReview.employee.firstName,employeeReview.employee.lastName)">
                    </div>
                    <div class="col-3">
                        <div class="alignUserName"></div>
                        <p class="h3">{{employeeReview.employee.firstName + ' ' + employeeReview.employee.lastName}}</p>
                        <p class="h5"><small class="text-muted">{{employeeReview.employee.jobTitle}}</small></p>
                        <p><small class="text-muted">{{ employeeReview.employee.department }}</small> </p>
                    </div>

                    <div class="col-6 col-sm-6 text   alignContent align-self-center">

                        <h2 *ngIf="employeeReview.reviewStatus == 'PENDING'"><span
                                class="badge badge-pill badge-danger badge-lg">{{employeeReview.reviewStatus}}</span>
                        </h2>
                        <h2 *ngIf="employeeReview.reviewStatus == 'COMPLETED'"><span
                                class="badge badge-pill badge-success badge-lg">{{employeeReview.reviewStatus}}</span>
                        </h2>
                        <h3 class="mt-0">Review Type : {{employeeReview.reviewType}}</h3>
                        <h3>Review Cycle : {{employeeReview.reviewCycle}}</h3>
                        <h3>Due Date : {{employeeReview.dueDate}}</h3>




                    </div>
                    <div class="col-2 alignContent text-center">

                        <div class="col-12"><button matTooltip="Evaluation Configuration" mat-flat-button
                                (click)="evaluationConfiguration(employeeReview)">
                                <mat-icon style="font-size: xx-large;" class="alignExpand" aria-hidden="false"
                                    aria-label="Example home icon">
                                    manage_accounts</mat-icon>
                            </button></div>
                        <div class="col-12 mt-3"><button matTooltip="Goal Sheet" mat-flat-button
                                (click)="viewGoalSheet(employeeReview)">
                                <mat-icon style="font-size: xx-large;" class="alignExpand" aria-hidden="false"
                                    aria-label="Example home icon">
                                    track_changes</mat-icon>
                            </button></div>
                        <div class="col-12 mt-3"><button matTooltip="Close Review" mat-flat-button
                                (click)="oneOnOneMeeting(employeeReview)">
                                <mat-icon *ngIf="employeeReview.reviewStatus == 'PENDING'" style="font-size: xx-large;"
                                    class="alignExpand" aria-hidden="false" aria-label="Example home icon">
                                    check_circle_outline</mat-icon>
                                <mat-icon *ngIf="employeeReview.reviewStatus == 'COMPLETED'"
                                    style="font-size: xx-large; color:#28a745" class="alignExpand" aria-hidden="false"
                                    aria-label="Example home icon">
                                    verified</mat-icon>
                            </button>
                        </div>

                        <div class="col-12 mt-3">
                            <button matTooltip="Average Score" mat-flat-button (click)="averageScore(employeeReview.employee.id,employeeReview.reviewCycle)">
                                <mat-icon style="font-size: xx-large;" class="alignExpand" aria-hidden="false"
                                    aria-label="Example home icon">
                                    assessment</mat-icon>
                            </button>
                        </div>

                        <!-- <p class="alignExpandText">Evaluation Configuratoin</p> -->
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>