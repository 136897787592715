import { BrowserModule  } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/header/header.component';
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import { MaterialModule } from './modules/material.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
// import { SelectAutocompleteModule } from "mat-select-autocomplete";
import { MatSelectModule } from '@angular/material/select';
import { DndModule } from 'ngx-drag-drop';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBadgeModule } from '@angular/material/badge'

import { MsalModule, MsalService } from '@azure/msal-angular';


import { SidenavService } from './services/sidenav.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FgHttpInterceptor } from './services/http-interceptor';
import { PrimengModule } from './modules/primeng.module';
import { MessageService } from 'primeng/api';
import { LoginComponent } from './components/login/login.component';

import { EditAppComponent } from './components/edit-app/edit-app.component';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { TestParentComponent } from './components/OpenModal/test-parent/test-parent.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { CommonModule } from '@angular/common';
import { FeedbackFormsComponent } from './components/feedback-forms/feedback-forms.component';
import { OAuthSettings } from 'src/oauth';
import { JwtInterceptor } from './services/jwt-intercepter'
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CircleLoaderComponent } from './components/circle-loader/circle-loader.component';
import { FeedbackGenerationComponent } from './components/feedback-generation/feedback-generation.component';
import { ProjectAccordianComponent } from './components/feedback-generation/project-accordian/project-accordian.component';
import { FeedbackGenerationDialogComponent } from './components/feedback-generation/feedback-generation-dialog/feedback-generation-dialog.component';
import { CreateFormsDialogComponent } from './components/feedback-generation/create-forms-dialog/create-forms-dialog.component';
import { FeedbackTabsComponent } from './components/feedback-submission/feedback-tabs/feedback-tabs.component';
import { FeedbackTabContentComponent } from './components/feedback-submission/feedback-tab-content/feedback-tab-content.component';
import { FormTemplateDialogComponent } from './components/feedback-submission/form-template-dialog/form-template-dialog/form-template-dialog.component';
import { EmployeeComponent } from './components/employee/employee.component';
import { MainContentComponent } from './components/employee/main-content/main-content.component';
import { EmployeeCardComponent } from './components/employee/employee-card/employee-card.component';
import { AddEmployeeCardComponent } from './components/employee/add-employee-card/add-employee-card.component';
import { ProjectComponent } from './components/project/project.component';
import { MainContentProjectComponent } from './components/project/main-content-project/main-content-project.component';
import { ProjectCardComponent } from './components/project/project-card/project-card.component';
import { AddProjectCardComponent } from './components/project/add-project-card/add-project-card.component';
import { ProjectDetailViewComponent } from './components/project/project-detail-view/project-detail-view.component';
import { CareerManagerComponent } from './components/career-manager/career-manager.component';
import { FeedbackGenerationCMComponent } from './components/career-manager/feedback-generation-cm/feedback-generation-cm.component';
import { DirectReporteesCMComponent } from './components/career-manager/direct-reportees-cm/direct-reportees-cm.component';
import { FeedbackFormGenerationComponent } from './components/feedback-form-generation/feedback-form-generation.component';
import { MainContentFeedbackComponent } from './components/feedback-form-generation/main-content-feedback/main-content-feedback.component';
import { FeedbackFormCardSharedComponent } from './components/feedback-form-generation/shared/feedback-form-card-shared/feedback-form-card-shared.component';
import { FeedbackFormCardNotificationComponent } from './components/feedback-generation/shared/feedback-form-card-notification/feedback-form-card-notification.component';
import { EvaluationConfigurationComponent } from './components/evaluation-configuration/evaluation-configuration.component';
import { OneOnOneMeetingComponent } from './components/one-on-one-meeting/one-on-one-meeting/one-on-one-meeting.component';
import { ParticipantCardComponent } from './components/one-on-one-meeting/participantCard/participant-card/participant-card.component';
import { GoalSheetComponent } from './components/career-manager/goal-sheet/goal-sheet.component';
import { GoalSheetAccordianComponent } from './components/career-manager/goal-sheet/goal-sheet-accordian/goal-sheet-accordian.component';
import { SubGoalTabComponent } from './components/career-manager/goal-sheet/goal-sheet-accordian/sub-goal-tab/sub-goal-tab.component';
import { ProgressBarComponent } from './components/career-manager/goal-sheet/progress-bar/progress-bar.component';
import { AddGoalComponent } from './components/career-manager/goal-sheet/goal-sheet-accordian/add-goal/add-goal.component';
import { GoalSheetViewEmployeeComponent } from './components/career-manager/goal-sheet/goal-sheet-view-employee/goal-sheet-view-employee.component';
import { AngularSpinnerComponent } from './components/angular-spinner/angular-spinner.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AverageScoreComponent } from './components/average-score/average-score.component';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';

import {ChartsModule} from 'ng2-charts'
import { HorizontalBarChartComponent } from './components/horizontal-bar-chart/horizontal-bar-chart.component';
import { AllEmployeeReviewComponent } from './components/all-employee-review/all-employee-review.component';
import { PagesComponent } from './components/pages/pages.component';
import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';
import {MatTabsModule} from '@angular/material/tabs';
import { HomeComponent } from './components/home/home.component';
import { LoginButtonComponent } from './components/login-button/login-button.component';
import { LeaveSummaryComponent } from './components/home/leave-summary/leave-summary.component';
import { UpcomingHolidaysComponent } from './components/home/upcoming-holidays/upcoming-holidays.component';
import { UpcomingEventsComponent } from './components/home/upcoming-events/upcoming-events.component';
import { MyProjectsComponent } from './components/home/my-projects/my-projects.component';
import { MyTeamComponent } from './components/home/my-team/my-team.component';
import { NotificationsCarouselComponent } from './components/home/notificationsCarousel/notificationsCarousel.component';


import { EmployeeUnmarkedComponent } from './components/employee/employee-unmarked/employee-unmarked.component';
import { DeleteEmployeeComponent } from './components/employee/delete-employee/delete-employee.component';

// import { DoughnutChartComponent, PieChartComponent, BarChartComponent } from 'angular-d3-charts';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LeftMenuComponent,
    LoginComponent,
    EditAppComponent,
    DynamicFormComponent,
    TestParentComponent,
    FeedbackFormsComponent,
    SpinnerComponent,
    CircleLoaderComponent,
    FeedbackGenerationComponent,
    ProjectAccordianComponent,
    FeedbackGenerationDialogComponent,
    CreateFormsDialogComponent,
    FeedbackTabsComponent,
    FeedbackTabContentComponent,
    FormTemplateDialogComponent,

    EmployeeComponent,
    MainContentComponent,
    EmployeeCardComponent,
    AddEmployeeCardComponent,
    ProjectComponent,
    MainContentProjectComponent,
    ProjectCardComponent,
    AddProjectCardComponent,
    ProjectDetailViewComponent,
    CareerManagerComponent,
    FeedbackGenerationCMComponent,
    DirectReporteesCMComponent,
    FeedbackFormGenerationComponent,
    MainContentFeedbackComponent,
    FeedbackFormCardSharedComponent,
    FeedbackFormCardNotificationComponent,
    EvaluationConfigurationComponent,
    OneOnOneMeetingComponent,
    ParticipantCardComponent,
    GoalSheetComponent,
    GoalSheetAccordianComponent,
    SubGoalTabComponent,
    ProgressBarComponent,
    AddGoalComponent,
    GoalSheetViewEmployeeComponent,
    AngularSpinnerComponent,
    AverageScoreComponent,
    DoughnutChartComponent,
    HorizontalBarChartComponent,
    AllEmployeeReviewComponent,
    PagesComponent,
    SidebarMenuComponent,
    HomeComponent,
    LoginButtonComponent,
    LeaveSummaryComponent,
    UpcomingHolidaysComponent,
    UpcomingEventsComponent,
    MyProjectsComponent,
    MyTeamComponent,
    NotificationsCarouselComponent,
    EmployeeUnmarkedComponent,
    DeleteEmployeeComponent
  ],
  imports: [
    BrowserModule,
    MatCardModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    PrimengModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SweetAlert2Module.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    DndModule,
    MatDialogModule,
    MatBadgeModule,
    NgbModule,
    MatNativeDateModule,
    MatDatepickerModule,
    CommonModule,
    MatPaginatorModule,
    LazyLoadImageModule,
    MatProgressBarModule,
    ChartsModule,
    MatTabsModule,
    // SelectAutocompleteModule,
    MsalModule.forRoot({
      auth: {
        clientId: OAuthSettings.appId,
        redirectUri: OAuthSettings.redirectUri,
        postLogoutRedirectUri: OAuthSettings.postLogoutRedirectUri
      }
    })
  ],
  providers: [SidenavService,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FgHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },

  ],
  bootstrap: [AppComponent],
  entryComponents: [EditAppComponent,
    DynamicFormComponent]
})
export class AppModule { }
