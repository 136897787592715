import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditAppComponent } from 'src/app/components/edit-app/edit-app.component';
import { FormTemplateDialogComponent } from 'src/app/components/feedback-submission/form-template-dialog/form-template-dialog/form-template-dialog.component';
import { FormSchemaDTO } from 'src/app/dtos/FormSchemaDTO';
import { FeedbackSubmitStatus } from 'src/app/services/feedback/feedback-enum';
import { FormsSchemaService } from 'src/app/services/forms/forms-schema.service';
import { Overlay } from '@angular/cdk/overlay';
@Component({
  selector: 'app-feedback-form-card-shared',
  templateUrl: './feedback-form-card-shared.component.html',
  styleUrls: ['./feedback-form-card-shared.component.scss']
})
export class FeedbackFormCardSharedComponent implements OnInit {

  @Input() feedbackForm;
  public formSectionCount;

  private feedbackFormDTO = new FormSchemaDTO();
  public newFormSchema = new FormSchemaDTO();
  formTemplatesModel: any = [];

  constructor(public formService: FormsSchemaService,
    private overlay: Overlay,
    private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  countFormSection(formTemplate) {
  }

  openEditDialog(feedback): void {
    const dialogRef = this.dialog.open(EditAppComponent, {
      width: "95%",
      height: "90%",
      data: {
        property: JSON.parse(feedback.formTemplate)
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined && feedback.id != undefined) {
        this.feedbackFormDTO.id = feedback.id;
        this.feedbackFormDTO.formTemplate = JSON.stringify(result)
        this.feedbackFormDTO.formType = result[0].description
        this.formService.updateFeedbackForm(this.feedbackFormDTO);
      }

    });
  }

  
  cloneNewFormDialog(feedback): void {
    const dialogRef = this.dialog.open(EditAppComponent, {
      width: "95%",
      height: "90%",
      data: {
        property: JSON.parse(feedback.formTemplate)
      },
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      feedback.id = null;
      if (result != undefined && feedback.id == null) {
        if (result != undefined) {
          this.newFormSchema.formTemplate = result;
          this.formTemplatesModel.push(result);
          this.saveForm(result)
        }
      }

    });
  }

  saveForm(feedbackFormInfo) {
    feedbackFormInfo.formTemplate = JSON.stringify(feedbackFormInfo);
    feedbackFormInfo.formType = feedbackFormInfo[0].description;
    this.formService.addNewFormSchemaSubscribe(feedbackFormInfo).subscribe(res => {
      this.formService.getFormPagination()
    });
  }


  openFormTemplateDialog(feedbackForm): void {
    if (feedbackForm != undefined) {
      const dialogRef = this.dialog.open(FormTemplateDialogComponent, {
        height: "97%",
        scrollStrategy: this.overlay.scrollStrategies.noop(),
        data: {
          property: feedbackForm.formTemplate,
          disableEdit: true
        },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(result => {
      });

    }
  }


  deleteFeedbackForm(feedbackForm) {
    this.formService.deleteFeedbackForm(feedbackForm.id)
  }
}
