<div class="container ml-4 mt-4">
    <div class="row">
        <div class="col-md-5">
                    <input id="searchQueryInput" type="text" name="searchQueryInput"
                        placeholder="Search" value="" />
                    <button id="searchQuerySubmit" type="submit" name="searchQuerySubmit">
                        <mat-icon>search</mat-icon>
                    </button>
        </div>
        <div class="col-md-4" >
                <app-login-button ></app-login-button>
            </div>
            <div class="col-md-3" >
            <button id="icon" *ngIf="this.userDetail.getUserDetails()">
                <mat-icon matTooltip="Notifications">notifications</mat-icon>
            </button>
        </div>
    </div>
</div>