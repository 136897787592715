<div class="card shadow-lg " style="width: 350px;">
    <div class="card-content">
        <div class="card-body">
            <div class="row">
                <div class="col-2"><img class="img-style"
                        [src]="this.initialAvatarService.getInitialImage(user.firstName,user.lastName)">
                </div>
                <div class="col-10 ">

                    <p class="h5"> {{this.userService.processName(this.user)}} </p>
                </div>
            </div>
        </div>
    </div>
</div>