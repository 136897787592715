import { UserDTO } from '../dtos/UserDTO';
import { Client } from './client';

interface IProject {
    id?: string;
    clientId?: Client;
    dateCreated?:Date;
    managerId?:string;
    projectName?:string;
    projectDescription?:string;
    supervisors?:UserDTO;
    users?:UserDTO;
    projectCode?:string;
    department?:string
  
}

export class Project {
    id: string;
    clientId: Client;
    dateCreated:Date;
    managerId:string;
    projectName: string;
    supervisors:UserDTO;
    users:UserDTO;
    projectDescription:string;
    projectCode:string;
    department:string;
    constructor(project: IProject) {
        if (project && project.id) {
            this.id = project.id;
        }
        this.clientId = project && project.clientId || null;
        this.supervisors = project && project.supervisors || null;
        this.users = project && project.users || null;
        this.managerId = project && project.managerId || null;
        this.projectName = project && project.projectName || null;
        this.dateCreated = project && project.dateCreated || null;
        this.projectDescription = project && project.projectDescription || null;
        this.projectCode = project && project.projectCode || null;
        this.department =  project && project.department || null;
    }
}