import { BlockScrollStrategy } from '@angular/cdk/overlay';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ProjectDTO } from 'src/app/dtos/ProjectDTO';
import { Feedback } from 'src/app/models/feedback';
import { Page } from 'src/app/services/feedback/feedback-enum';
import { FeedbackService } from 'src/app/services/feedback/feedback.service';
import { FormsSchemaService } from 'src/app/services/forms/forms-schema.service';
import { InitialAvatarService } from 'src/app/services/initialAvatar/initial-avatar.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { UserDetailsService } from 'src/app/services/user-details.service';
import { FeedbackGenerationDialogComponent } from '../../feedback-generation/feedback-generation-dialog/feedback-generation-dialog.component';
@Component({
  selector: 'app-feedback-generation-cm',
  templateUrl: './feedback-generation-cm.component.html',
  styleUrls: ['./feedback-generation-cm.component.scss']
})
export class FeedbackGenerationCMComponent implements OnInit {

  public project = new ProjectDTO();

  constructor(private dialog: MatDialog,
    private formSchemaService: FormsSchemaService,
    public feedbackService: FeedbackService,
    private notificationService:NotificationService,
    public initialAvatarService: InitialAvatarService,
    private userDetailService: UserDetailsService) { }

  ngOnInit(): void {

    this.feedbackService.getFeedbackByCareerManagerIdSubscribe(this.userDetailService.getUserDetails().id).subscribe(res => {
      this.feedbackService.setFeedback(res.body)

    })

  }

  openGenerateDialog() {

    this.formSchemaService.getAllFormsSubcribe().subscribe(res => {
      
      if (res.body.length > 0) {

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = "95%";
        dialogConfig.height = "90%";
        dialogConfig.panelClass = ['panelClassFeedbackGenerationForm'];
        // dialogConfig.data = this.project;
        dialogConfig.data = {
          property: this.project,
          page: Page.Project
        }
        const dialogRef = this.dialog.open(FeedbackGenerationDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
        });

      }else{
        this.notificationService.info("No Form Template are created")
      }
    })


  }

  processName(user) {
    let name;
    if (user && user.firstName)
      name = user.firstName

    if (user && user.lastName) {
      name ? name += " " + user.lastName : name = user.lastName;
    }

    return name;
  }

  processDesignation(user) {
    let designation;
    if (user && user.firstName)
      return designation = user.jobTitle
  }

  emailreminder(value : Feedback) {
   const userReviewTo = value.userReviewTo.id;
   const userReviewFrom = value.userReviewFrom.id;
    this.feedbackService.feedbackEmailReminderSetting(userReviewTo, userReviewFrom).subscribe(data => {
      alert("hi")
      console.log(data + " #54321");
      if (data) {
        setTimeout(() => {
          this.notificationService.sucess("Email reminder sent successfull");
        }, 2000);
      }
    })

  }

  // processCreatedDate(createdDateString){
  //   let now = Date.now();
  //   let createdDate = new Date(createdDateString)

  //   let diffInMilliSeconds:any = Math.abs(now - createdDate)


  //   // calculate days
  //   const days = Math.floor(diffInMilliSeconds / 86400);
  //   diffInMilliSeconds -= days * 86400;
  //   console.log('calculated days', days);

  //   // calculate hours
  //   const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  //   diffInMilliSeconds -= hours * 3600;
  //   console.log('calculated hours', hours);

  //   // calculate minutes
  //   const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  //   diffInMilliSeconds -= minutes * 60;
  //   console.log('minutes', minutes);

  //   // calculate seconds
  //   const seconds = Math.floor(diffInMilliSeconds / 1000) % 1000;
  //   diffInMilliSeconds -= seconds * 1000;
  //   console.log('seconds', seconds);
  // }

}
