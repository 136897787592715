import { UserDTO } from "../dtos/UserDTO";
interface ICareerManagerTeam {
    id?: string;
    capability?: string;
    careerManagerId?: string;
    peers?: UserDTO[];
    careerManagerName?:String;
} 
export class CareerManagerTeam {
    id: string;
    capability: string;
    careerManagerId: string;
    peers: UserDTO[];
    careerManagerName:String;
    constructor(careerManagerTeam: ICareerManagerTeam) {
        if (careerManagerTeam && careerManagerTeam.id) {
            this.id = careerManagerTeam.id;
        }
        this.capability = careerManagerTeam && careerManagerTeam.capability || null;
        this.careerManagerId = careerManagerTeam && careerManagerTeam.careerManagerId || null;
        this.peers = careerManagerTeam && careerManagerTeam.peers || null;
        this.careerManagerName = careerManagerTeam && careerManagerTeam.careerManagerName || null;
    }
}