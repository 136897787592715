import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { FormSchemaDTO } from 'src/app/dtos/FormSchemaDTO';
import { ApiService } from 'src/app/services/api.service';
import { LoginService } from 'src/app/services/login.service';
import { StorageService } from 'src/app/services/storage.service';
import { EditAppComponent } from '../edit-app/edit-app.component';
@Component({
  selector: 'app-feedback-forms',
  templateUrl: './feedback-forms.component.html',
  styleUrls: ['./feedback-forms.component.scss']
})
export class FeedbackFormsComponent implements OnInit {
  feedbackForm = new FormSchemaDTO();
  feedbackIdFormControl = new FormControl();
  public projectList = [];
  filteredOptions: Observable<string[]>;
  dropdownList = [];
  public data;
  public sendValue;
  public editData;
  public viewData;
  public isPresent: boolean = false;
  public isProject: boolean = false;
  step = 0;
  setStep(index: number) {
    this.step = index;
  }
  nextStep() {
    this.step++;
  }
  prevStep() {
    this.step--;
  }
  constructor(private apiService: ApiService,
    private storageService: StorageService,
    private loginService: LoginService,
    private dialog: MatDialog, config: NgbCarouselConfig) { }
  ngOnInit(): void {
    this.getProjects();
    this.filteredOptions = this.feedbackIdFormControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)
      )
    );
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.projectList.filter(option => option.projectName.toLowerCase().includes(filterValue));
  }
  onProjectIdChange(event) {
    if (event.length == 1) {
      this.isProject = true;
    }
  }
  displayFn(id) {
    let result = "";
    if (this.projectList != undefined) {
      for (let element of this.projectList) {
        if (element.id === id) {
          result = element.projectName;
          break;
        }
      };
    }
    else {
    }
    return result;
  }
  saveForm(feedbackFormInfo) {
    feedbackFormInfo.formTemplate = JSON.stringify(this.data);
    this.loginService.addNewFormSchema(feedbackFormInfo);
  }
  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.width = "70%";
    dialogConfig.height = "70%";
    data: { pageValue: this.sendValue }
    let dialogRef = this.dialog.open(EditAppComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.data = result;
        this.isPresent = true;
      }
    });
  }
  editDialog() {
    const editDialogConfig = new MatDialogConfig();
    editDialogConfig.autoFocus = true;
    editDialogConfig.width = "90%";
    editDialogConfig.height = "80%";
    editDialogConfig.data = this.data;
    data: { pageValue: this.sendValue }
    let editDialogRef = this.dialog.open(EditAppComponent, editDialogConfig);
    this.editData = editDialogConfig.data;
    this.isPresent = false;
    editDialogRef.afterClosed().subscribe(editResult => {
      this.isPresent = true;
    });
  }
  getProjects() {
    this.apiService.getAllProjects(this.storageService.getItem(StorageService.JWT_TOKEN)).subscribe(res => {
      this.loginService.response = res;
      for (var i = 0; i < this.loginService.response.length; i++) {
        this.projectList.push(this.loginService.response[i])
      }
      this.dropdownList = this.projectList;
    }, err => { })
  }
}
